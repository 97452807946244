const ldl = {
  parameters: [
    {
      name: 'LDL',
      displayName: 'LDL',
      id: 'ldl',
      validFrom: '2022/05/24 1:00',
      validThrough: null,
      interpretationsSupported: [
        'normal',
        'too-high',
      ],
      ranges: [{
        color: '#1f7359',
        description: 'LDL-normal',
        lowerLimit: 0,
        lowerLimitOperator: '>=',
        upperLimit: 3,
        upperLimitOperator: '<',
        unit: 'mmol/l',
        interpretation: 'normal',
        displayInterpretation: 'Normal',
      },
      {
        color: '#EF4036',
        description: 'LDL-too-high',
        lowerLimit: 3,
        lowerLimitOperator: '>=',
        upperLimit: 6,
        upperLimitOperator: '',
        unit: 'mmol/l',
        interpretation: 'too-high',
        displayInterpretation: 'Too high',
      }],
    },
  ],
};

export default ldl;