import { Pagination, PaginationProps } from 'react-admin';
import React from 'react';

const Paginator = ({
  perPage,
  ...paginationProps
}: JSX.IntrinsicAttributes & PaginationProps & { perPage: number }) => (
  <Pagination
    rowsPerPageOptions={[]}
    defaultValue={perPage}
    {...paginationProps}
  />
);

export default Paginator;
