const luesScreen = {
  manualInterpretation: false,
  parameters: [
    {
      name: 'syphilis',
      id: 'syphilis',
      displayName: 'Syphilis',
      labReportName: 'Infektserologie - Lues Screen (IgG, IgM)',
      validFrom: '2022/01/01 0:00',
      validThrough: null,
      stringValuesSupported: [
        'positive',
        'negative',
        'fault',
      ],
      outcomes: [{
        color: '#1f7359',
        description: 'syphilis-negative',
        name: 'negative',
        displayInterpretation: 'Negative'
      },
      {
        color: '#f52d0a',
        description: 'syphilis-positive',
        name: 'positive',
        displayInterpretation: 'Positive',
      },
      {
        color: '#000000',
        description: 'syphilis-fault',
        name: 'fault',
        displayInterpretation: 'Fault',
      }],
    },
  ],
};

export default luesScreen;