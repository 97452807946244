const vitaminB12 = {
  parameters: [
    {
      name: 'vitamin-b12',
      id: 'vitamin-b12',
      displayName: 'Vitamin B12',
      validFrom: '2021/01/05 1:00',
      validThrough: null,
      interpretationsSupported: [
        'deficiency',
        'risk',
        'normal',
        'surplus',
        'fault',
      ],
      ranges: [{
        color: '#f52d0a',
        description: 'vitamin-B12-deficiency',
        lowerLimit: 0,
        lowerLimitOperator: '>=',
        upperLimit: 156,
        upperLimitOperator: '<',
        unit: 'pmol/l',
        interpretation: 'deficiency',
        displayInterpretation: 'Deficiency',
      },
      {
        color: '#fdad02',
        description: 'vitamin-B12-risk',
        lowerLimit: 156,
        lowerLimitOperator: '>=',
        upperLimit: 295,
        upperLimitOperator: '<=',
        unit: 'pmol/l',
        interpretation: 'risk',
        displayInterpretation: 'Risk',
      },
      {
        color: '#1f7359',
        description: 'vitamin-B12-normal',
        lowerLimit: 295,
        lowerLimitOperator: '>',
        upperLimit: 651,
        upperLimitOperator: '<=',
        unit: 'pmol/l',
        interpretation: 'normal',
        displayInterpretation: 'Normal',
      },
      {
        color: '#ffffff',
        description: 'vitamin-B12-surplus',
        lowerLimit: 651,
        lowerLimitOperator: '>',
        upperLimit: 701,
        upperLimitOperator: '',
        unit: 'pmol/l',
        interpretation: 'surplus',
        displayInterpretation: 'Surplus',
      }],
    },
  ],
};

export default vitaminB12;