import { InputLabel } from '@material-ui/core';
import React from 'react';
import {
  Show,
  TextField,
  ShowProps,
  useShowController,
  FileField,
  CardContentInner,
  FieldTitle,
  useLocale,
  useTranslate,
  EmailField,
  RichTextField,
} from 'react-admin';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import VideoField from '../../../components/VideoComponent';
import messages from '../messages';
import VideoReviewForm from './VideoReviewForm';

const PrescriptionShow = (props: ShowProps) => {
  const { record } = useShowController(props);
  const locale = useLocale();
  const translate = useTranslate();

  // TODO: update this
  const hasReasonForCallback =
    !!record?.reasonForCallback?.proofOfPreviousUse ||
    !!record?.reasonForCallback?.conditions ||
    !!record?.reasonForCallback?.medications ||
    !!record?.reasonForCallback?.smoking ||
    !!record?.reasonForCallback?.bmi;

  return (
    <Show {...props}>
      <CardContentInner>
        <div className="show-layout__field">
          <InputLabel className="label" htmlFor="typeOfRequest" shrink>
            <FieldTitle label={messages.typeOfRequest} source="typeOfRequest" />
          </InputLabel>
          <div className="show-layout__text">
            <TextField
              className="text-input__show"
              label={messages.typeOfRequest}
              source={`typeOfRequest.${locale}`}
            />
          </div>
        </div>

        <div className="show-layout__field">
          <InputLabel className="label" htmlFor="orderType" shrink>
            <FieldTitle label={messages.orderType} source="orderType" />
          </InputLabel>
          <div className="show-layout__text">
            <TextField
              className="text-input__show"
              label={messages.orderType}
              source={`orderType.${locale}`}
            />
          </div>
        </div>

        <div className="show-layout__field">
          <InputLabel className="label" htmlFor="healthConcern" shrink>
            <FieldTitle label={messages.healthConcern} source="healthConcern" />
          </InputLabel>
          <div className="show-layout__text">
            <TextField
              className="text-input__show"
              label={messages.healthConcern}
              source={`healthConcern.${locale}`}
            />
          </div>
        </div>

        {hasReasonForCallback && (
          <div className="show-layout__field">
            <InputLabel className="label" htmlFor="reasonForCallback" shrink>
              <FieldTitle
                label={messages.reasonForCallback}
                source="reasonForCallback"
              />
            </InputLabel>

            {record && record.reasonForCallback && (
              <div className="show-layout__text">
                {record.reasonForCallback.proofOfPreviousUse && (
                  <TextField
                    className="text-input__show"
                    label={messages.reasonForCallback}
                    source={`reasonForCallback.proofOfPreviousUse.${locale}`}
                  />
                )}
                {record.reasonForCallback.conditions && (
                  <TextField
                    className="text-input__show"
                    label={messages.reasonForCallback}
                    source={`reasonForCallback.conditions.${locale}`}
                  />
                )}
                {record.reasonForCallback.medications && (
                  <TextField
                    className="text-input__show"
                    label={messages.reasonForCallback}
                    source={`reasonForCallback.medications.${locale}`}
                  />
                )}
                {record.reasonForCallback.smoking && (
                  <TextField
                    className="text-input__show"
                    label={messages.reasonForCallback}
                    source={`reasonForCallback.smoking.${locale}`}
                  />
                )}
                {record.reasonForCallback.bmi && (
                  <TextField
                    className="text-input__show"
                    label={messages.reasonForCallback}
                    source={`reasonForCallback.bmi.${locale}`}
                  />
                )}
              </div>
            )}
          </div>
        )}

        {hasReasonForCallback && (
          <div className="show-layout__field">
            <InputLabel className="label" htmlFor="callbackTime" shrink>
              <FieldTitle label={messages.callbackTime} source="callbackTime" />
            </InputLabel>
            <div className="show-layout__text">
              <TextField
                className="text-input__show"
                label={messages.callbackTime}
                source={`callbackTime.${locale}`}
              />
            </div>
          </div>
        )}

        {record &&
          record.medicines &&
          record.medicines.map((medicine: any, index: number) => (
            <div
              className="show-layout__field show-layout__nested"
              key={medicine.name}
            >
              <InputLabel
                className="label show-layout__label-with-addition"
                htmlFor="medicines[index]"
                shrink
              >
                <FieldTitle
                  label={messages.medicine}
                  source="medicines[index]"
                />
                {record.medicines.length > 1 && (
                  <span className="show-layout__label-addition">
                    {' '}
                    {index + 1}
                  </span>
                )}
              </InputLabel>

              <div className="show-layout__field show-layout__copy">
                <InputLabel className="label" htmlFor="name" shrink>
                  <FieldTitle
                    label={messages.itemName}
                    source={messages.itemName}
                  />
                </InputLabel>
                <div className="show-layout__text">
                  <TextField
                    className="text-input__show"
                    label={messages.itemName}
                    source={`medicines[${index}].name`}
                  />
                </div>
              </div>

              <div className="show-layout__field show-layout__copy">
                <InputLabel className="label" htmlFor="GTIN" shrink>
                  <FieldTitle label={messages.GTIN} source={messages.GTIN} />
                </InputLabel>
                {medicine.GTIN && (
                  <div className="show-layout__text">
                    <CopyToClipboard text={medicine.GTIN}>
                      <div className="icon-copy" />
                    </CopyToClipboard>
                    <TextField
                      className="text-input__show"
                      label={messages.GTIN}
                      source={`medicines[${index}].GTIN`}
                    />
                  </div>
                )}
              </div>

              <div className="show-layout__field show-layout__copy">
                <InputLabel className="label" htmlFor="type" shrink>
                  <FieldTitle label={messages.type} source={messages.type} />
                </InputLabel>
                <div className="show-layout__text">
                  <TextField
                    className="text-input__show"
                    label={messages.type}
                    source={`medicines[${index}].type.${locale}`}
                  />
                </div>
              </div>

              <div className="show-layout__field show-layout__copy">
                <InputLabel className="label" htmlFor="packageSize" shrink>
                  <FieldTitle
                    label={messages.packageSize}
                    source="packageSize"
                  />
                </InputLabel>
                <div className="show-layout__text">
                  <TextField
                    className="text-input__show"
                    label={messages.packageSize}
                    source={`medicines[${index}].packageSize.${locale}`}
                  />
                </div>
              </div>

              <div className="show-layout__field show-layout__copy">
                <InputLabel className="label" htmlFor="numberOfPackages" shrink>
                  <FieldTitle
                    label={messages.numberOfPackages}
                    source="numberOfPackages"
                  />
                </InputLabel>
                <div className="show-layout__text">
                  <TextField
                    className="text-input__show"
                    label={messages.numberOfPackages}
                    source={`medicines[${index}].numberOfPackages.${locale}`}
                  />
                </div>
              </div>
            </div>
          ))}

        <div className="show-layout__field">
          <InputLabel className="label" htmlFor="videoForReview" shrink>
            <FieldTitle
              label={messages.videoForReview}
              source="videoForReview"
            />
          </InputLabel>
          <div className="video-field">
            <div className="show-layout__text">
              <VideoField
                className="text-input__show"
                source="videoForReview"
              />
            </div>
          </div>
        </div>
        <div className="show-layout__field">
          <InputLabel className="label" htmlFor="proofOfPreviousUse" shrink>
            <FieldTitle
              label={messages.proofOfPreviousUse}
              source="proofOfPreviousUse"
            />
          </InputLabel>
          <div className="show-layout__text">
            <FileField
              target="_blank"
              className="text-input__show"
              source="proofOfPreviousUse.0"
              title={translate(messages.proofOfPreviousUse)}
            />
          </div>
        </div>
        <div className="show-layout__field">
          <InputLabel className="label" htmlFor="questionnaire" shrink>
            <FieldTitle label={messages.documents} source="questionnaire" />
          </InputLabel>
          <div className="show-layout__text">
            <FileField
              target="_blank"
              className="text-input__show"
              source="questionnaire.0"
              title="Questionnaire"
            />
          </div>
        </div>
        <div className="show-layout__field">
          <InputLabel className="label" htmlFor="freeText" shrink>
            <FieldTitle label={messages.freeText} source="freeText" />
          </InputLabel>
          <div className="show-layout__text">
            <RichTextField
              className="text-input__show"
              source="freeText"
              title={translate(messages.freeText)}
            />
          </div>
        </div>

        <div className="show-layout__field">
          <InputLabel className="label" shrink>
            <FieldTitle label={messages.approval} />
          </InputLabel>
          <div className="video-field">
            <VideoReviewForm
              id={record?.id.toString() || ''}
              status={record?.status}
              rejectionReason={record?.rejectionReason}
              hasReasonForCallback={hasReasonForCallback}
            />
          </div>
        </div>

        <div className="show-layout__field show-layout__copy">
          <InputLabel className="label" htmlFor="lastName" shrink>
            <FieldTitle label={messages.lastName} source="lastName" />
          </InputLabel>
          <div className="show-layout__text">
            <CopyToClipboard text={record?.lastName}>
              <div className="icon-copy" />
            </CopyToClipboard>
            <TextField
              className="text-input__show"
              label={messages.lastName}
              source="lastName"
            />
          </div>
        </div>

        <div className="show-layout__field show-layout__copy">
          <InputLabel className="label" htmlFor="firstName" shrink>
            <FieldTitle label={messages.firstName} source="firstName" />
          </InputLabel>
          <div className="show-layout__text">
            <CopyToClipboard text={record?.firstName}>
              <div className="icon-copy" />
            </CopyToClipboard>
            <TextField
              className="text-input__show"
              label={messages.firstName}
              source="firstName"
            />
          </div>
        </div>

        <div className="show-layout__field show-layout__copy">
          <InputLabel className="label" htmlFor="dateOfBirth" shrink>
            <FieldTitle label={messages.dateOfBirth} source="dateOfBirth" />
          </InputLabel>
          <div className="show-layout__text">
            <CopyToClipboard text={record?.dateOfBirth}>
              <div className="icon-copy" />
            </CopyToClipboard>
            <TextField
              className="text-input__show"
              label={messages.dateOfBirth}
              source="dateOfBirth"
            />
          </div>
        </div>

        <div className="show-layout__field show-layout__copy">
          <InputLabel className="label" htmlFor="phone" shrink>
            <FieldTitle label={messages.phone} source="phone" />
          </InputLabel>
          <div className="show-layout__text">
            <CopyToClipboard text={record?.phone}>
              <div className="icon-copy" />
            </CopyToClipboard>
            <TextField
              className="text-input__show"
              label={messages.phone}
              source="phone"
            />
          </div>
        </div>

        <div className="show-layout__field show-layout__copy">
          <InputLabel className="label" htmlFor="email" shrink>
            <FieldTitle label={messages.email} source="email" />
          </InputLabel>
          <div className="show-layout__text">
            <CopyToClipboard text={record?.email}>
              <div className="icon-copy" />
            </CopyToClipboard>
            <EmailField source="email" />
          </div>
        </div>

        <div className="show-layout__field show-layout__nested">
          <InputLabel className="label" htmlFor="billingAddress" shrink>
            <FieldTitle
              label={messages.billingAddress}
              source="billingAddress"
            />
          </InputLabel>

          <div className="show-layout__field show-layout__copy">
            <InputLabel className="label" htmlFor="streetAndNumber" shrink>
              <FieldTitle label={messages.street} source="streetAndNumber" />
            </InputLabel>
            <div className="show-layout__text">
              <CopyToClipboard text={record?.billingAddress?.streetAndNumber}>
                <div className="icon-copy" />
              </CopyToClipboard>
              <TextField
                className="text-input__show"
                label={messages.street}
                source="billingAddress.streetAndNumber"
              />
            </div>
          </div>
          <div className="show-layout__field show-layout__copy">
            <InputLabel className="label" htmlFor="zipCode" shrink>
              <FieldTitle label={messages.postalCode} source="zipCode" />
            </InputLabel>
            <div className="show-layout__text">
              <CopyToClipboard text={record?.billingAddress?.zipCode}>
                <div className="icon-copy" />
              </CopyToClipboard>
              <TextField
                className="text-input__show"
                label={messages.postalCode}
                source="billingAddress.zipCode"
              />
            </div>
          </div>
          <div className="show-layout__field show-layout__copy">
            <InputLabel className="label" htmlFor="town" shrink>
              <FieldTitle label={messages.town} source="town" />
            </InputLabel>
            <div className="show-layout__text">
              <CopyToClipboard text={record?.billingAddress?.town}>
                <div className="icon-copy" />
              </CopyToClipboard>
              <TextField
                className="text-input__show"
                label={messages.town}
                source="billingAddress.town"
              />
            </div>
          </div>
        </div>

        <div className="show-layout__field show-layout__nested">
          <InputLabel className="label" htmlFor="shippingAddress" shrink>
            <FieldTitle
              label={messages.shippingAddress}
              source="shippingAddress"
            />
          </InputLabel>

          <div className="show-layout__field show-layout__copy">
            <InputLabel className="label" htmlFor="streetAndNumber" shrink>
              <FieldTitle label={messages.street} source="streetAndNumber" />
            </InputLabel>
            <div className="show-layout__text">
              <CopyToClipboard text={record?.deliveryAddress?.streetAndNumber}>
                <div className="icon-copy" />
              </CopyToClipboard>
              <TextField
                className="text-input__show"
                label={messages.street}
                source="deliveryAddress.streetAndNumber"
              />
            </div>
          </div>
          <div className="show-layout__field show-layout__copy">
            <InputLabel className="label" htmlFor="zipCode" shrink>
              <FieldTitle label={messages.postalCode} source="zipCode" />
            </InputLabel>
            <div className="show-layout__text">
              <CopyToClipboard text={record?.deliveryAddress?.zipCode}>
                <div className="icon-copy" />
              </CopyToClipboard>
              <TextField
                className="text-input__show"
                label={messages.postalCode}
                source="deliveryAddress.zipCode"
              />
            </div>
          </div>
          <div className="show-layout__field show-layout__copy">
            <InputLabel className="label" htmlFor="town" shrink>
              <FieldTitle label={messages.town} source="town" />
            </InputLabel>
            <div className="show-layout__text">
              <CopyToClipboard text={record?.deliveryAddress?.town}>
                <div className="icon-copy" />
              </CopyToClipboard>
              <TextField
                className="text-input__show"
                label={messages.town}
                source="deliveryAddress.town"
              />
            </div>
          </div>
        </div>
      </CardContentInner>
    </Show>
  );
};
export default PrescriptionShow;
