const uricAcid = {
  parameters: [
    {
      name: 'uricAcid-female',
      id: 'uricAcid',
      displayName: 'Uric acid',
      validFrom: '2022/05/24 1:00',
      validThrough: null,
      interpretationsSupported: [
        'deficiency',
        'normal',
        'too-high',
        'fault',
      ],
      criteria: {
        sexe: [
          'female',
        ],
      },
      ranges: [{
        color: '#ffffff',
        description: 'uricAcid-female-deficiency',
        lowerLimit: 0,
        lowerLimitOperator: '>=',
        upperLimit: 140,
        upperLimitOperator: '<',
        unit: 'mcmol/l',
        interpretation: 'deficiency',
        displayInterpretation: 'Deficiency',
        treatAs: 'normal',
      },
      {
        color: '#1f7359',
        description: 'uricAcid-female-normal',
        lowerLimit: 140,
        lowerLimitOperator: '>=',
        upperLimit: 360,
        upperLimitOperator: '<=',
        unit: 'mcmol/l',
        interpretation: 'normal',
        displayInterpretation: 'Normal',
      },
      {
        color: '#f52d0a',
        description: 'uricAcid-female-too-high',
        lowerLimit: 360,
        lowerLimitOperator: '>',
        upperLimit: 600,
        upperLimitOperator: '',
        unit: 'mcmol/l',
        interpretation: 'too-high',
        displayInterpretation: 'Too high',
      }],
    },
    {
      name: 'uricAcid-male',
      id: 'uricAcid',
      displayName: 'Uric acid',
      validFrom: '2022/03/07 1:00',
      validThrough: null,
      interpretationsSupported: [
        'deficiency',
        'normal',
        'too-high',
      ],
      criteria: {
        sexe: [
          'male',
        ],
      },
      ranges: [{
        color: '#ffffff',
        description: 'uricAcid-male-deficiency',
        lowerLimit: 0,
        lowerLimitOperator: '>=',
        upperLimit: 210,
        upperLimitOperator: '<',
        unit: 'mcmol/l',
        interpretation: 'deficiency',
        displayInterpretation: 'Deficiency',
        treatAs: 'normal',
      },
      {
        color: '#1f7359',
        description: 'uricAcid-male-normal',
        lowerLimit: 210,
        lowerLimitOperator: '>=',
        upperLimit: 420,
        upperLimitOperator: '<=',
        unit: 'mcmol/l',
        interpretation: 'normal',
        displayInterpretation: 'Normal',
      },
      {
        color: '#f52d0a',
        description: 'uricAcid-male-too-high',
        lowerLimit: 420,
        lowerLimitOperator: '>',
        upperLimit: 800,
        upperLimitOperator: '',
        unit: 'mcmol/l',
        interpretation: 'too-high',
        displayInterpretation: 'Too high',
      }],
    },
  ],
};

export default uricAcid;