import React from 'react';
import { Layout, LayoutProps } from 'react-admin';
import CustomAppBar from '../CustomAppBar';
import CustomMenu from '../CustomMenu';
import './styles.scss';

const CustomLayout = ({ children, ...props }: LayoutProps) => (
  <Layout {...props} appBar={CustomAppBar} menu={CustomMenu}>
    {children}
  </Layout>
);

export default CustomLayout;
