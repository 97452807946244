import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import { Button } from '@material-ui/core';

import './styles.scss';
import { useTranslate } from 'react-admin';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const ConfirmationModal = ({
  open,
  handleClose,
  confirmationFunction,
  loading,
}: {
  open: boolean;
  handleClose: () => void;
  confirmationFunction: () => void;
  loading: boolean;
}) => {
  const translate = useTranslate();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {translate('testResults.reportFormModalTitle')}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {translate('testResults.reportFormModalDescription')}
        </Typography>
        <div className="button-wrapper">
          <Button
            variant="contained"
            color="primary"
            onClick={confirmationFunction}
            disabled={loading}
          >
            {translate('testResults.reportFormModalYes')}
          </Button>
          <Button variant="contained" color="default" onClick={handleClose}>
            {translate('testResults.reportFormModalNo')}
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default ConfirmationModal;
