import React from 'react';

import {
  NumberInput,
  TextInput,
  BooleanInput,
  DateInput,
  DateTimeInput,
  InputProps,
} from 'react-admin';

const getInputByType = (
  type: 'number' | 'string' | 'boolean' | 'date' | 'datetime',
  props: InputProps,
) => {
  switch (type) {
    case 'number':
      return <NumberInput key={props.source} {...props} />;
    case 'string':
      return <TextInput key={props.source} {...props} />;
    case 'boolean':
      return <BooleanInput key={props.source} {...props} />;
    case 'date':
      return <DateInput key={props.source} {...props} />;
    case 'datetime':
      return <DateTimeInput key={props.source} {...props} />;
    default:
      return <TextInput key={props.source} {...props} />;
  }
};
export default getInputByType;
