import React from 'react';
import { SaveButton, Button, Toolbar, useRefresh } from 'react-admin';
// @ts-ignore
import { useHistory } from 'react-router-dom';
import { MASS_TESTS_MODULE } from '../../constants/apiUrls';
import useApproveDraft from '../../hooks/useApproveDraft';

const GridListToolbar = ({
  clickHandler,
  ...props
}: {
  clickHandler: (redirect?: Function) => void;
}) => {
  const { record, basePath, invalid, handleSubmitWithRedirect }: any = props;
  const history = useHistory();
  const refresh = useRefresh();
  const update = useApproveDraft('create', `${MASS_TESTS_MODULE}/${record.id}`);
  return (
    <Toolbar
      handleSubmitWithRedirect={() => {
        clickHandler(handleSubmitWithRedirect);
      }}
      record={record}
      basePath={basePath}
      invalid={invalid}
    >
      <SaveButton
        onSave={() => {
          clickHandler(handleSubmitWithRedirect);
        }}
        disabled={invalid}
        label="approve"
        submitOnEnter
      />
      <Button
        style={{ marginLeft: '15px' }}
        size="medium"
        label="ra.action.cancel"
        onClick={() =>
          history.push({
            pathname: `${basePath}`,
            state: { clientId: record?.id || 0 },
          })
        }
        submitOnEnter={false}
      />
      <Button
        style={{ marginLeft: '5%' }}
        size="medium"
        label="Reset sync-errors"
        onClick={() => update(record.id).then(() => refresh())}
        submitOnEnter={false}
      />
    </Toolbar>
  );
};

export default GridListToolbar;
