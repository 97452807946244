import React from 'react';
import { useFormState } from 'react-final-form';

export const ErrorsCount = () => {
  const form = useFormState();
  return (
    <div>
      Errors Count:{' '}
      {form.values?.errorsCount?.reduce(
        (prev: number, cur: number) => prev + cur,
        0,
      )}
    </div>
  );
};
