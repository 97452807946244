
import { useNotify } from 'react-admin';
import { AxiosError, AxiosResponse } from 'axios';
import useCustomCreate from './useCustomCreate';
import isAxiosError from '../models/isAxiosErrorGuard';


const useRowSave = (type: string, resource: string, firstPartId: string) => {
  const notify = useNotify();
  const update = useCustomCreate(type);
  const handleSave = async (value: any) => update(`${resource}/${firstPartId}`, value.id, value, null, {
    returnPromise: true,
  })?.then((errObject: AxiosError | AxiosResponse) => {
    let enErrorObject = {};
    if (isAxiosError(errObject)) {
      const errors = errObject.response?.data || {};
      enErrorObject = Object.assign({}, ...errors);
      const errorsArr: string[] = Object.values(errors);
      if (errorsArr.length > 0) {
        errorsArr.forEach((err) => { notify(err) })
      }
      return enErrorObject;
    }
    return enErrorObject;
  })
  return handleSave;
};
export default useRowSave;

