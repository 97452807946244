const ferritinSante24 = {
  parameters: [
    {
      name: 'sante24-ferritin-all-5-18',
      displayName: 'Ferritin',
      id: 'ferritin',
      validFrom: '2023/06/22 1:00',
      validThrough: null,
      interpretationsSupported: [
        'deficiency',
        'normal',
        'surplus',
        'fault',
      ],
      criteria: {
        sexe: [
          'female',
          'male',
        ],
        age: {
          min: 5,
          max: 18,
        },
      },
      ranges: [{
        color: '#f52d0a',
        description: 'sante24-ferritin-all-5-18-ironDeficiency',
        lowerLimit: 0,
        lowerLimitOperator: '>=',
        upperLimit: 6,
        upperLimitOperator: '<',
        unit: 'µg/l',
        interpretation: 'deficiency',
        displayInterpretation: 'Deficiency',
      },
      {
        color: '#1f7359',
        description: 'sante24-ferritin-all-5-18-normalIronLevel',
        lowerLimit: 6,
        lowerLimitOperator: '>=',
        upperLimit: 320,
        upperLimitOperator: '<=',
        unit: 'µg/l',
        interpretation: 'normal',
        displayInterpretation: 'Normal',
      },
      {
        color: '#ffffff',
        description: 'sante24-ferritin-all-5-18-tooMuchIron',
        lowerLimit: 320,
        lowerLimitOperator: '>',
        upperLimit: 350,
        upperLimitOperator: '',
        unit: 'µg/l',
        interpretation: 'surplus',
        displayInterpretation: 'Surplus',
        treatAs: 'normal',
      }],
    },
    {
      name: 'sante24-ferritin-male-18-110',
      displayName: 'Ferritin',
      id: 'ferritin',
      validFrom: '2023/06/22 1:00',
      validThrough: null,
      interpretationsSupported: [
        'deficiency',
        'normal',
        'surplus',
        'fault',
      ],
      criteria: {
        sexe: [
          'male',
        ],
        age: {
          min: 18,
          max: 110,
        },
      },
      ranges: [{
        color: '#f52d0a',
        description: 'sante24-ferritin-male-18-110-ironDeficiency',
        lowerLimit: 0,
        lowerLimitOperator: '>=',
        upperLimit: 30,
        upperLimitOperator: '<',
        unit: 'µg/l',
        interpretation: 'deficiency',
        displayInterpretation: 'Deficiency',
      },
      {
        color: '#1f7359',
        description: 'sante24-ferritin-male-18-110-normalIronLevel',
        lowerLimit: 30,
        lowerLimitOperator: '>=',
        upperLimit: 300,
        upperLimitOperator: '<=',
        unit: 'µg/l',
        interpretation: 'normal',
        displayInterpretation: 'Normal',
      },
      {
        color: '#ffffff',
        description: 'sante24-ferritin-male-18-110-tooMuchIron',
        lowerLimit: 300,
        lowerLimitOperator: '>',
        upperLimit: 330,
        upperLimitOperator: '',
        unit: 'µg/l',
        interpretation: 'surplus',
        displayInterpretation: 'Surplus',
        treatAs: 'normal',
      }],
    },
    {
      name: 'sante24-ferritin-female-18-45',
      displayName: 'Ferritin',
      id: 'ferritin',
      validFrom: '2023/06/22 1:00',
      validThrough: null,
      interpretationsSupported: [
        'deficiency',
        'normal',
        'surplus',
        'fault',
      ],
      criteria: {
        sexe: [
          'female',
        ],
        age: {
          min: 18,
          max: 45,
        },
      },
      ranges: [{
        color: '#f52d0a',
        description: 'sante24-ferritin-female-18-45-ironDeficiency',
        lowerLimit: 0,
        lowerLimitOperator: '>=',
        upperLimit: 50,
        upperLimitOperator: '<',
        unit: 'µg/l',
        interpretation: 'deficiency',
        displayInterpretation: 'Deficiency',
      },
      {
        color: '#1f7359',
        description: 'sante24-ferritin-female-18-45-normalIronLevel',
        lowerLimit: 50,
        lowerLimitOperator: '>=',
        upperLimit: 150,
        upperLimitOperator: '<=',
        unit: 'µg/l',
        interpretation: 'normal',
        displayInterpretation: 'Normal',
      },
      {
        color: '#ffffff',
        description: 'sante24-ferritin-female-18-45-tooMuchIron',
        lowerLimit: 150,
        lowerLimitOperator: '>',
        upperLimit: 170,
        upperLimitOperator: '',
        unit: 'µg/l',
        interpretation: 'surplus',
        displayInterpretation: 'Surplus',
        treatAs: 'normal',
      }],
    },
    {
      name: 'sante24-ferritin-female-45-110',
      displayName: 'Ferritin',
      id: 'ferritin',
      validFrom: '2021/01/05 1:00',
      validThrough: null,
      interpretationsSupported: [
        'deficiency',
        'normal',
        'surplus',
        'fault',
      ],
      criteria: {
        sexe: [
          'female',
        ],
        age: {
          min: 45,
          max: 110,
        },
      },
      ranges: [{
        color: '#f52d0a',
        description: 'sante24-ferritin-female-45-110-ironDeficiency',
        lowerLimit: 0,
        lowerLimitOperator: '>=',
        upperLimit: 30,
        upperLimitOperator: '<',
        unit: 'µg/l',
        interpretation: 'deficiency',
        displayInterpretation: 'Deficiency',
      },
      {
        color: '#1f7359',
        description: 'sante24-ferritin-female-45-110-normalIronLevel',
        lowerLimit: 30,
        lowerLimitOperator: '>=',
        upperLimit: 190,
        upperLimitOperator: '<=',
        unit: 'µg/l',
        interpretation: 'normal',
        displayInterpretation: 'Normal',
      },
      {
        color: '#ffffff',
        description: 'sante24-ferritin-female-45-110-tooMuchIron',
        lowerLimit: 190,
        lowerLimitOperator: '>',
        upperLimit: 220,
        upperLimitOperator: '',
        unit: 'µg/l',
        interpretation: 'surplus',
        displayInterpretation: 'Surplus',
        treatAs: 'normal',
      }],
    },
  ],
};

export default ferritinSante24;