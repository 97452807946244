const kreatinin = {
  displayName: 'Kreatinin',
  parameters: [
    {
      name: 'kreatinin-female-12-110',
      displayName: 'Kreatinin',
      id: 'kreatinin',
      validFrom: '2022/03/07 1:00',
      validThrough: null,
      interpretationsSupported: [
        'deficiency',
        'normal',
        'too-high',
        'fault',
      ],
      criteria: {
        sexe: [
          'female',
        ],
        age: {
          min: 12,
          max: 110,
        },
      },
      ranges: [{
        color: '#ffffff',
        description: 'kreatinin-female-12-110-deficiency',
        lowerLimit: 0,
        lowerLimitOperator: '>=',
        upperLimit: 45,
        upperLimitOperator: '<',
        unit: 'mcmol/l',
        interpretation: 'deficiency',
        displayInterpretation: 'Deficiency',
        treatAs: 'normal',
      },
      {
        color: '#1f7359',
        description: 'kreatinin-female-12-110-normal',
        lowerLimit: 45,
        lowerLimitOperator: '>=',
        upperLimit: 88.4,
        upperLimitOperator: '<=',
        unit: 'mcmol/l',
        interpretation: 'normal',
        displayInterpretation: 'Normal',
      },
      {
        color: '#f52d0a',
        description: 'kreatinin-female-12-110-too-high',
        lowerLimit: 88.4,
        lowerLimitOperator: '>',
        upperLimit: 200,
        upperLimitOperator: '',
        unit: 'mcmol/l',
        interpretation: 'too-high',
        displayInterpretation: 'Too high',
      }],
    },
    {
      name: 'kreatinin-male-12-110',
      displayName: 'Kreatinin',
      id: 'kreatinin',
      validFrom: '2022/03/07 1:00',
      validThrough: null,
      interpretationsSupported: [
        'deficiency',
        'normal',
        'too-high',
        'fault',
      ],
      criteria: {
        sexe: [
          'male',
        ],
        age: {
          min: 12,
          max: 110,
        },
      },
      ranges: [{
        color: '#ffffff',
        description: 'kreatinin-male-12-110-deficiency',
        lowerLimit: 0,
        lowerLimitOperator: '>=',
        upperLimit: 59,
        upperLimitOperator: '<',
        unit: 'mcmol/l',
        interpretation: 'deficiency',
        displayInterpretation: 'Deficiency',
        treatAs: 'normal',
      },
      {
        color: '#1f7359',
        description: 'kreatinin-male-12-110-normal',
        lowerLimit: 59,
        lowerLimitOperator: '>=',
        upperLimit: 114.9,
        upperLimitOperator: '<=',
        unit: 'mcmol/l',
        interpretation: 'normal',
        displayInterpretation: 'Normal',
      },
      {
        color: '#f52d0a',
        description: 'kreatinin-male-12-110-too-high',
        lowerLimit: 114.9,
        lowerLimitOperator: '>',
        upperLimit: 200,
        upperLimitOperator: '',
        unit: 'mcmol/l',
        interpretation: 'too-high',
        displayInterpretation: 'Too high',
      }],
    },
  ],
};

export default kreatinin;