const hpvGenotyping = {
  name: 'hpvGenotyping',
  labReportName: 'HPV Genotypisierung [HPVG60]',
  // Check where is this used
  manualInterpretation: false,
  interpretationsSupported: ['negative', 'positive', 'fault'],
  parameters: [
    {
      name: 'hpvLowRisk',
      id: 'hpvLowRisk',
      labReportName: 'HPV (niedriges Risiko) [HPVB60]',
      validFrom: '2024/01/01 1:00',
      validThrough: null,
      stringValuesSupported: ['negative', 'positive', 'fault'],
      outcomes: [
        {
          color: '#1f7359',
          description: 'hpvLowRisk-negative',
          displayInterpretation: 'Negative',
          name: 'negative',
        },
        {
          color: '#f52d0a',
          description: 'hpvLowRisk-positive',
          name: 'positive',
          displayInterpretation: 'Positive',
        },
        {
          color: '#000000',
          description: 'hpvLowRisk-fault',
          name: 'fault',
          displayInterpretation: 'Fault',
        },
      ],
    },
    {
      name: 'hpvPotentiallyHighRisk',
      id: 'hpvPotentiallyHighRisk',
      labReportName: 'HPV (potentiell hohes Risiko) [HPVF60]',
      validFrom: '2024/01/01 1:00',
      validThrough: null,
      stringValuesSupported: ['negative', 'positive', 'fault'],
      outcomes: [
        {
          color: '#1f7359',
          description: 'hpvPotentiallyHighRisk-negative',
          name: 'negative',
          displayInterpretation: 'Negative',
        },
        {
          color: '#f52d0a',
          description: 'hpvPotentiallyHighRisk-positive',
          name: 'positive',
          displayInterpretation: 'Positive',
        },
        {
          color: '#000000',
          description: 'hpvPotentiallyHighRisk-fault',
          name: 'fault',
          displayInterpretation: 'Fault',
        },
      ],
    },
    {
      name: 'hpvHighRisk',
      id: 'hpvHighRisk',
      labReportName: 'HPV (hohes Risiko) [HPVH60]',
      validFrom: '2024/01/01 1:00',
      validThrough: null,
      stringValuesSupported: ['negative', 'positive', 'fault'],
      outcomes: [
        {
          color: '#1f7359',
          description: 'hpvHighRisk-negative',
          name: 'negative',
          displayInterpretation: 'Negative',
        },
        {
          color: '#f52d0a',
          description: 'hpvHighRisk-positive',
          name: 'positive',
          displayInterpretation: 'Positive',
        },
        {
          color: '#000000',
          description: 'hpvHighRisk-fault',
          name: 'fault',
          displayInterpretation: 'Fault',
        },
      ],
    },
  ],
};

export default hpvGenotyping;