const hiv = {
  manualInterpretation: false,
  parameters: [
    {
      name: 'hiv',
      displayName: 'HIV',
      id: 'hiv',
      labReportName: 'Infektserologie - HIV Ag/Ak',
      validFrom: '2022/07/01 22:00',
      validThrough: null,
      stringValuesSupported: [
        'positive',
        'negative',
        'fault',
      ],
      outcomes: [{
        color: '#1f7359',
        description: 'hiv-negative',
        name: 'negative',
        displayInterpretation: 'Negative'
      },
      {
        color: '#f52d0a',
        description: 'hiv-positive',
        name: 'positive',
        displayInterpretation: 'Positive',
      },
      {
        color: '#000000',
        description: 'hiv-fault',
        name: 'fault',
        displayInterpretation: 'Fault',
      }],
    },
    {
      name: 'ak',
      id: 'ak',
      displayName: 'HIV - AK',
      validFrom: '2022/01/01 0:00',
      validThrough: '2022/07/01 21:59',
      stringValuesSupported: [
        'positive',
        'negative',
        'fault',
      ],
      outcomes: [{
        color: '#1f7359',
        description: 'ak-negative',
        name: 'negative',
        displayInterpretation: 'Negative',
      },
      {
        color: '#f52d0a',
        description: 'ak-positive',
        name: 'positive',
        displayInterpretation: 'Positive',
      },
      {
        color: '#000000',
        description: 'ak-fault',
        name: 'fault',
        displayInterpretation: 'Fault',
      }],
    },
    {
      name: 'ag',
      displayName: 'HIV - AG',
      validFrom: '2022/01/01 0:00',
      validThrough: '2022/07/01 21:59',
      stringValuesSupported: [
        'positive',
        'negative',
        'fault',
      ],
      outcomes: [{
        color: '#1f7359',
        description: 'ag-negative',
        name: 'negative',
        displayInterpretation: 'Negative',
      },
      {
        color: '#f52d0a',
        description: 'ag-positive',
        name: 'positive',
        displayInterpretation: 'Positive',
      },
      {
        color: '#000000',
        description: 'ag-fault',
        name: 'fault',
        displayInterpretation: 'Fault',
      }],
    },
  ],
};

export default hiv;