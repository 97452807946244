import React, { ChangeEvent } from 'react';
import { Button, useTranslate, FileField } from 'react-admin';
import DeleteIcon from '@mui/icons-material/Delete';
import { Divider } from '@material-ui/core';
import SectionTitle from '../../SectionTitle';
import CustomSelect from '../../CustomSelect';
import CustomUploadButton from '../../CustomUploadButton';
import { monthsMap, allMedicines } from './data';
import CustomCheckbox from '../../CustomCheckbox';
import { Medication, Medicine } from '../Medication/types';

interface ImmunoTherapyTreatmentProps {
  patientEligible: string | undefined;
  selectedTherapyType: string | undefined;
  setSelectedTherapyType: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  // scitPossible: boolean | undefined;
  slitPossible: boolean | undefined;
  medications: Medication[];
  preparatoryCallRequired: string | undefined;
  setPatientEligible: React.Dispatch<React.SetStateAction<string | undefined>>;
  setPreparatoryCallRequired: React.Dispatch<React.SetStateAction<string>>;
  setScitPossible: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  setSlitPossible: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  setMedications: React.Dispatch<React.SetStateAction<Medication[]>>;
  fileInputRef: React.RefObject<HTMLInputElement>;
  readonly?: boolean;
}

const ImmunotherapyTreatment: React.FC<ImmunoTherapyTreatmentProps> = ({
  patientEligible,
  setPatientEligible,
  selectedTherapyType,
  setSelectedTherapyType,
  setScitPossible,
  setSlitPossible,
  slitPossible,
  medications,
  fileInputRef,
  setMedications,
  readonly,
  setPreparatoryCallRequired,
  preparatoryCallRequired,
}) => {
  const translate = useTranslate();

  const handlePatientEligibleChange = (
    event: ChangeEvent<HTMLSelectElement>,
  ) => {
    setPatientEligible(event.target.value);
  };

  const handlePreparatoryCallRequiredChange = (
    event: ChangeEvent<HTMLSelectElement>,
  ) => {
    setPreparatoryCallRequired(event.target.value);
  };

  const handleTherapyTypeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedTherapyType(event.target.value);
    setScitPossible(
      event.target.value === 'SCIT' || event.target.value === 'SCIT & SLIT',
    );
    setSlitPossible(event.target.value === 'SCIT & SLIT');
  };

  const handleMedicationChange = (
    index: number,
    event: ChangeEvent<HTMLSelectElement>,
  ) => {
    const affectedMedicine = allMedicines.find(
      (m) => m.name === event.target.value,
    ) as Medicine;
    setMedications(
      medications?.map((medication, i) => {
        if (index === i) {
          return {
            ...medication,
            medicine: affectedMedicine,
          };
        }
        return medication;
      }),
    );
  };

  const handleRemoveMedication = (index: number) => {
    setMedications(medications.filter((medication, i) => i !== index));
  };

  const handleMedicationMonthsChange = (
    index: number,
    month: string,
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    setMedications(
      medications?.map((medication, i) => {
        if (index === i) {
          if (event.target.checked) {
            return {
              ...medication,
              startMonths: [...medication.startMonths, month],
            };
          }
          return {
            ...medication,
            startMonths: medication.startMonths.filter((m) => m !== month),
          };
        }
        return medication;
      }),
    );
  };

  const handleAddMedication = () => {
    setMedications([...medications, { medicine: null, startMonths: [] }]);
  };

  return (
    <div className="formWrapper">
      <SectionTitle title={translate('immunotherapy.treatment.title')} />
      <div className="treatmentBlock">
        <div className="treatmentBlock-text">
          {translate('immunotherapy.treatment.questionEligibility')}
        </div>
        <CustomSelect
          emptyLabel={translate('immunotherapy.treatment.selectEligibility')}
          options={[
            {
              value: 'true',
              label: translate('immunotherapy.treatment.yes'),
            },
            {
              value: 'false',
              label: translate('immunotherapy.treatment.no'),
            },
          ]}
          value={patientEligible}
          onChange={handlePatientEligibleChange}
          disabled={readonly}
          error={!patientEligible}
        />
      </div>
      {patientEligible === 'true' && (
        <>
          <div className="treatmentBlock">
            <div className="treatmentBlock-text">
              {translate('immunotherapy.treatment.questionTherapyType')}
            </div>
            <CustomSelect
              emptyLabel={translate(
                'immunotherapy.treatment.selectTherapyType',
              )}
              options={[
                {
                  value: 'SCIT',
                  label: translate('immunotherapy.treatment.SCITLabel'),
                },
                {
                  value: 'SCIT & SLIT',
                  label: translate('immunotherapy.treatment.SLITLabel'),
                },
              ]}
              value={selectedTherapyType}
              onChange={handleTherapyTypeChange}
              disabled={readonly}
              error={patientEligible === 'true' && !selectedTherapyType}
            />
          </div>
          {slitPossible && (
            <div className="extraMarginTop">
              {medications.map((medication, index) => (
                <div
                  key={`medication-${
                    medication.medicine?.name
                  }-${Math.random()}`}
                >
                  <div className="treatmentBlock medication">
                    <div className="medicationColumn treatmentBlock-text">
                      {translate(
                        'immunotherapy.treatment.recommendedMedication',
                      )}
                    </div>
                    <div className="medicationColumn">
                      <CustomSelect
                        emptyLabel={translate(
                          'immunotherapy.treatment.selectMedication',
                        )}
                        options={allMedicines.map((m) => ({
                          value: m.name,
                          label: m.name,
                        }))}
                        value={medication.medicine?.name}
                        onChange={(event) =>
                          handleMedicationChange(index, event)
                        }
                        disabled={readonly}
                        error={!medication.medicine}
                      />
                    </div>
                    <div className="medicationColumn medicationColumn-trashCan">
                      {!readonly && (
                        <Button
                          className="deleteButton"
                          variant="text"
                          color="secondary"
                          onClick={() => handleRemoveMedication(index)}
                        >
                          <DeleteIcon color="error" />
                        </Button>
                      )}
                    </div>
                  </div>
                  <div className="treatmentBlock">
                    <div
                      className={`treatmentBlock-text ${
                        medication.startMonths.length > 0 ? '' : 'error-text'
                      }`}
                    >
                      {translate(
                        'immunotherapy.treatment.recommendedMedicationStart',
                      )}
                    </div>
                    <div className="monthsSelection">
                      {monthsMap.map((month) => (
                        <div
                          key={`month-${month.value}`}
                          className="monthCheckbox"
                        >
                          <CustomCheckbox
                            label={month.shortLabel}
                            checked={medication.startMonths.includes(
                              month.value,
                            )}
                            disabled={readonly}
                            onChange={(event) =>
                              handleMedicationMonthsChange(
                                index,
                                month.value,
                                event,
                              )
                            }
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                  <Divider />
                </div>
              ))}
              {!readonly && (
                <div className="treatmentBlock">
                  <Button
                    variant="contained"
                    label={`+ ${translate(
                      'immunotherapy.treatment.addAdditionalMedication',
                    )}`}
                    color="primary"
                    onClick={handleAddMedication}
                  />
                </div>
              )}
              <div className="treatmentBlock">
                <div className="treatmentBlock-text">
                  {translate(
                    'immunotherapy.treatment.questionPreparatoryCallRequired',
                  )}
                </div>
                <CustomSelect
                  emptyLabel={translate(
                    'immunotherapy.treatment.selectEligibility',
                  )}
                  options={[
                    {
                      value: 'true',
                      label: translate('immunotherapy.treatment.yes'),
                    },
                    {
                      value: 'false',
                      label: translate('immunotherapy.treatment.no'),
                    },
                  ]}
                  value={preparatoryCallRequired}
                  onChange={handlePreparatoryCallRequiredChange}
                  disabled={readonly}
                />
              </div>
              {!readonly && preparatoryCallRequired === 'false' && (
                <div className="treatmentBlock">
                  <div className="treatmentBlockUploadLabel">
                    {translate('immunotherapy.treatment.uploadPrescription')}
                  </div>
                  <CustomUploadButton inputRef={fileInputRef} />
                </div>
              )}
              {readonly && (
                <div className="treatmentBlock">
                  <FileField
                    target="_blank"
                    className="text--purple"
                    source="immunotherapy.prescriptionUrl"
                    title={translate(
                      'immunotherapy.treatment.openPrescription',
                    )}
                  />
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ImmunotherapyTreatment;
