import React from 'react';
import { useRecordContext } from 'react-admin';

import { TestNames } from '../../../constants/testNames';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TestTypeField = ({ source, label }: { source: any; label?: string }) => {
  const record = useRecordContext(source);
  return (
    <span>{record && TestNames[record[source] as keyof typeof TestNames]}</span>
  );
};

export default TestTypeField;
