const hbsAntigen = {
  hidden: true,
  parameters: [
    {
      name: 'hbsAntigen',
      id: 'hbsAntigen',
      displayName: 'Hepatitis B - HBs-Antigen',
      labReportName: 'Hepatitis B - HBs-Antigen',
      validFrom: '2022/01/01 0:00',
      validThrough: null,
      stringValuesSupported: [
        'positive',
        'negative',
        'fault',
      ],
      outcomes: [{
        color: '#1f7359',
        description: 'hbsAntigen-negative',
        name: 'negative',
        displayInterpretation: 'Negative'
      },
      {
        color: '#f52d0a',
        description: 'hbsAntigen-positive',
        name: 'positive',
        displayInterpretation: 'Positive',
      },
      {
        color: '#000000',
        description: 'hbsAntigen-fault',
        name: 'fault',
        displayInterpretation: 'Fault',
      }],
    },
  ],
};

export default hbsAntigen;
