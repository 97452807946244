import React, { useState } from 'react';
import { Button, CircularProgress, TextField } from '@material-ui/core';
import { useNotify, useRedirect, useMutation, useTranslate } from 'react-admin';
import ConfirmationModal from '../DoctorReportForm/ConfirmationModal';
import messages from './messages';

import './styles.scss';

const PharmacyReportForm = ({ record }: { record: any }) => {
  const notify = useNotify();
  const translate = useTranslate();
  const redirect = useRedirect();
  const [report, setReport] = useState<string>('');
  const [openModal, setOpenModal] = useState<boolean>(false);

  const [addReport, { loading }] = useMutation(
    {
      type: 'update',
      resource: 'tests',
      payload: {
        id: record.id,
        data: {
          isPharmacyReport: true,
          report,
          doctorName: null,
          pharmacyName: 'Stauffacher Apotheke',
        },
      },
    },
    {
      onSuccess: () => {
        redirect('/tests/doctorReportNeededStateTests');
        notify(messages.addReportSuccessMessage, { type: 'success' });
      },
      onFailure: () =>
        notify(messages.addReportErrorMessage, { type: 'warning' }),
    },
  );

  const handleReportTextareaChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setReport(event.target.value);
  };

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (report === '') {
      setOpenModal(true);
    } else {
      addReport();
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <ConfirmationModal
        handleClose={handleCloseModal}
        open={openModal}
        confirmationFunction={addReport}
        loading={loading || false}
      />
      <form onSubmit={handleFormSubmit} className="form">
        <TextField
          className="textarea"
          id="report"
          label={translate(messages.textareaLabel)}
          multiline
          minRows={8}
          value={report}
          variant="outlined"
          onChange={(event) => handleReportTextareaChange(event)}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className="button"
          disabled={loading}
        >
          {loading ? (
            <CircularProgress size="24.5px" />
          ) : (
            translate(messages.buttonText)
          )}
        </Button>
      </form>
    </>
  );
};

export default PharmacyReportForm;
