import React from 'react';
import { Show, ShowProps, useShowController } from 'react-admin';

import { Divider } from '@material-ui/core';
import UserDataBlock from '../../../components/UserDataBlock';
import ImmunotherapyAnswers from '../../../components/ImmunotherapyAnswers';
import ImmunotherapyForm from '../../../components/ImmonotherapyForm';

import './styles.scss';

const ReviewedImmunotherapyTestShow = (props: ShowProps) => {
  const {
    record,
    // error,
    // loading
  } = useShowController(props);

  return (
    <Show {...props}>
      <>
        <UserDataBlock record={record} />
        <Divider className="divider" />
        <ImmunotherapyAnswers />
        <Divider className="divider" />
        <ImmunotherapyForm record={record} readonly />
      </>
    </Show>
  );
};

export default ReviewedImmunotherapyTestShow;
