// @ts-ignore
import { useHistory } from 'react-router-dom';
import { SIGN_UP_URL, USERS_MODULE } from '../constants/apiUrls';
import useCustomCreate from './useCustomCreate';

const useAdminSave = (type: string) => {
  const update = useCustomCreate(type);
  const history = useHistory();
  const handleSave = async (value: {
    id: string;
    username: string;
    password: string;
    roles: string[];
    partnerCode: string;
  }) =>
    update(SIGN_UP_URL, value.id, value, null, {
      returnPromise: true,
    })?.then((errObject: Record<string, string> | { data: { id: string } }) => {
      if (!(typeof errObject.data === 'object')) {
        const errors = errObject || {};
        return errors;
      }
      history.push('');
      history.push(`${USERS_MODULE}`);
      return undefined;
    });
  return handleSave;
};
export default useAdminSave;
