const smallBloodCount = {
  manualInterpretation: true,
  interpretationsSupported: [
    'normal',
    'deviating',
    'fault',
  ],
  parameters: [
    {
      name: 'hemoglobine-female',
      displayName: 'Hemoglobine',
      id: 'hemoglobine',
      labReportName: 'Hämoglobinkonzentration [Hb]',
      validFrom: '2021/01/05 1:00',
      validThrough: null,
      interpretationsSupported: [
        'deficiency',
        'normal',
        'high',
        'fault',
      ],
      criteria: {
        sexe: [
          'female',
        ],
      },
      ranges: [
        {
          color: '#ffffff',
          description: 'hemoglobine-female-deficiency',
          lowerLimit: 0,
          lowerLimitOperator: '>=',
          upperLimit: 120,
          upperLimitOperator: '<',
          unit: 'g/l',
          interpretation: 'deficiency',
          displayInterpretation: 'Deficiency',
        },
        {
          color: '#ffffff',
          description: 'hemoglobine-female-normal',
          lowerLimit: 120,
          lowerLimitOperator: '>=',
          upperLimit: 160,
          upperLimitOperator: '<=',
          unit: 'g/l',
          interpretation: 'normal',
          displayInterpretation: 'Normal',
        },
        {
          color: '#ffffff',
          description: 'hemoglobine-female-high',
          lowerLimit: 160,
          lowerLimitOperator: '>',
          upperLimit: 200,
          upperLimitOperator: '',
          unit: 'g/l',
          interpretation: 'high',
          displayInterpretation: 'High',
        },
      ],
    },
    {
      name: 'hemoglobine-male',
      displayName: 'Hemoglobine',
      id: 'hemoglobine',
      labReportName: 'Hämoglobinkonzentration [Hb]',
      validFrom: '2021/01/05 1:00',
      validThrough: null,
      interpretationsSupported: [
        'deficiency',
        'normal',
        'high',
        'fault',
      ],
      criteria: {
        sexe: [
          'male',
        ],
      },
      ranges: [
        {
          color: '#ffffff',
          description: 'hemoglobine-male-deficiency',
          lowerLimit: 0,
          lowerLimitOperator: '>=',
          upperLimit: 140,
          upperLimitOperator: '<',
          unit: 'g/l',
          interpretation: 'deficiency',
          displayInterpretation: 'Deficiency',
        },
        {
          color: '#ffffff',
          description: 'hemoglobine-male-normal',
          lowerLimit: 140,
          lowerLimitOperator: '>=',
          upperLimit: 180,
          upperLimitOperator: '<=',
          unit: 'g/l',
          interpretation: 'normal',
          displayInterpretation: 'Normal',
        },
        {
          color: '#ffffff',
          description: 'hemoglobine-male-high',
          lowerLimit: 180,
          lowerLimitOperator: '>',
          upperLimit: 220,
          upperLimitOperator: '',
          unit: 'g/l',
          interpretation: 'high',
          displayInterpretation: 'High',
        },
      ],
    },
    {
      name: 'hematocrite-female',
      displayName: 'Hematocrite',
      id: 'hematocrite',
      labReportName: 'Hämatokrit [Hkt]',
      validFrom: '2021/01/05 1:00',
      validThrough: null,
      interpretationsSupported: [
        'deficiency',
        'normal',
        'high',
        'fault',
      ],
      criteria: {
        sexe: [
          'female',
        ],
      },
      ranges: [
        {
          color: '#ffffff',
          description: 'hematocrite-female-deficiency',
          lowerLimit: 0,
          lowerLimitOperator: '>=',
          upperLimit: 0.37,
          upperLimitOperator: '<',
          unit: 'l/l',
          interpretation: 'deficiency',
          displayInterpretation: 'Deficiency',
        },
        {
          color: '#ffffff',
          description: 'hematocrite-female-normal',
          lowerLimit: 0.37,
          lowerLimitOperator: '>=',
          upperLimit: 0.47,
          upperLimitOperator: '<=',
          unit: 'l/l',
          interpretation: 'normal',
          displayInterpretation: 'Normal',
        },
        {
          color: '#ffffff',
          description: 'hematocrite-female-high',
          lowerLimit: 0.47,
          lowerLimitOperator: '>',
          upperLimit: 0.60,
          upperLimitOperator: '',
          unit: 'l/l',
          interpretation: 'high',
          displayInterpretation: 'High',
        },
      ],
    },
    {
      name: 'hematocrite-male',
      displayName: 'Hematocrite',
      id: 'hematocrite',
      labReportName: 'Hämatokrit [Hkt]',
      validFrom: '2021/01/05 1:00',
      validThrough: null,
      interpretationsSupported: [
        'deficiency',
        'normal',
        'high',
        'fault',
      ],
      criteria: {
        sexe: [
          'male',
        ],
      },
      ranges: [
        {
          color: '#ffffff',
          description: 'hematocrite-male-deficiency',
          lowerLimit: 0,
          lowerLimitOperator: '>=',
          upperLimit: 0.4,
          upperLimitOperator: '<',
          unit: 'l/l',
          interpretation: 'deficiency',
          displayInterpretation: 'Deficiency',
        },
        {
          color: '#ffffff',
          description: 'hematocrite-male-normal',
          lowerLimit: 0.4,
          lowerLimitOperator: '>=',
          upperLimit: 0.54,
          upperLimitOperator: '<=',
          unit: 'l/l',
          interpretation: 'normal',
          displayInterpretation: 'Normal',
        },
        {
          color: '#ffffff',
          description: 'hematocrite-male-high',
          lowerLimit: 0.54,
          lowerLimitOperator: '>',
          upperLimit: 0.64,
          upperLimitOperator: '',
          unit: 'l/l',
          interpretation: 'high',
          displayInterpretation: 'High',
        },
      ],
    },
    {
      name: 'leucocyteCount',
      displayName: 'Leucocyte Count',
      id: 'leucocyteCount',
      labReportName: 'Leukozytenzahl [WBC] (white blood cells)',
      validFrom: '2021/01/05 1:00',
      validThrough: null,
      interpretationsSupported: [
        'deficiency',
        'normal',
        'high',
        'fault',
      ],
      ranges: [
        {
          color: '#ffffff',
          description: 'leucocyteCount-deficiency',
          lowerLimit: 0,
          lowerLimitOperator: '>=',
          upperLimit: 4,
          upperLimitOperator: '<',
          unit: 'G/l',
          interpretation: 'deficiency',
          displayInterpretation: 'Deficiency',
        },
        {
          color: '#ffffff',
          description: 'leucocyteCount-normal',
          lowerLimit: 4,
          lowerLimitOperator: '>=',
          upperLimit: 10,
          upperLimitOperator: '<=',
          unit: 'G/l',
          interpretation: 'normal',
          displayInterpretation: 'Normal',
        },
        {
          color: '#ffffff',
          description: 'leucocyteCount-high',
          lowerLimit: 10,
          lowerLimitOperator: '>',
          upperLimit: 12,
          upperLimitOperator: '',
          unit: 'G/l',
          interpretation: 'high',
          displayInterpretation: 'High',
        },
      ],
    },
    {
      name: 'erytrocyteCount-female',
      displayName: 'Erytrocyte Count',
      id: "erytrocyteCount",
      labReportName: 'Erythrozytenzahl [RBC] (red blood cells)',
      validFrom: '2021/01/05 1:00',
      validThrough: null,
      interpretationsSupported: [
        'deficiency',
        'normal',
        'high',
        'fault',
      ],
      criteria: {
        sexe: [
          'female',
        ],
      },
      ranges: [
        {
          color: '#ffffff',
          description: 'erytrocyteCount-female-deficiency',
          lowerLimit: 0,
          lowerLimitOperator: '>=',
          upperLimit: 4.3,
          upperLimitOperator: '<',
          unit: 'T/l',
          interpretation: 'deficiency',
          displayInterpretation: 'Deficiency',
        },
        {
          color: '#ffffff',
          description: 'erytrocyteCount-female-normal',
          lowerLimit: 4.3,
          lowerLimitOperator: '>=',
          upperLimit: 5.2,
          upperLimitOperator: '<=',
          unit: 'T/l',
          interpretation: 'normal',
          displayInterpretation: 'Normal',
        },
        {
          color: '#ffffff',
          description: 'erytrocyteCount-female-high',
          lowerLimit: 5.2,
          lowerLimitOperator: '>',
          upperLimit: 6.5,
          upperLimitOperator: '',
          unit: 'T/l',
          interpretation: 'high',
          displayInterpretation: 'High',
        },
      ],
    },
    {
      name: 'erytrocyteCount-male',
      displayName: 'Erytrocyte Count',
      id: "erytrocyteCount",
      labReportName: 'Erythrozytenzahl [RBC] (red blood cells)',
      validFrom: '2021/01/05 1:00',
      validThrough: null,
      interpretationsSupported: [
        'deficiency',
        'normal',
        'high',
        'fault',
      ],
      criteria: {
        sexe: [
          'male',
        ],
      },
      ranges: [
        {
          color: '#ffffff',
          description: 'erytrocyteCount-male-deficiency',
          lowerLimit: 0,
          lowerLimitOperator: '>=',
          upperLimit: 4.8,
          upperLimitOperator: '<',
          unit: 'T/l',
          interpretation: 'deficiency',
          displayInterpretation: 'Deficiency',
        },
        {
          color: '#ffffff',
          description: 'erytrocyteCount-male-normal',
          lowerLimit: 4.8,
          lowerLimitOperator: '>=',
          upperLimit: 5.9,
          upperLimitOperator: '<=',
          unit: 'T/l',
          interpretation: 'normal',
          displayInterpretation: 'Normal',
        },
        {
          color: '#ffffff',
          description: 'erytrocyteCount-male-high',
          lowerLimit: 5.9,
          lowerLimitOperator: '>',
          upperLimit: 7.0,
          upperLimitOperator: '',
          unit: 'T/l',
          interpretation: 'high',
          displayInterpretation: 'High',
        },
      ],
    },
    {
      name: 'erytrocyteDistribution',
      displayName: 'Erytrocyte Distribution',
      id: "erytrocyteDistribution",
      labReportName: 'Erythrozytenverteilungsbreite [RDW]',
      validFrom: '2021/01/05 1:00',
      validThrough: null,
      interpretationsSupported: [
        'deficiency',
        'normal',
        'high',
        'fault',
      ],
      ranges: [
        {
          color: '#ffffff',
          description: 'erytrocyteDistribution-deficiency',
          lowerLimit: 0,
          lowerLimitOperator: '>=',
          upperLimit: 11.9,
          upperLimitOperator: '<',
          unit: '%',
          interpretation: 'deficiency',
          displayInterpretation: 'Deficiency',
        },
        {
          color: '#ffffff',
          description: 'erytrocyteDistribution-normal',
          lowerLimit: 11.9,
          lowerLimitOperator: '>=',
          upperLimit: 14.5,
          upperLimitOperator: '<=',
          unit: '%',
          interpretation: 'normal',
          displayInterpretation: 'Normal',
        },
        {
          color: '#ffffff',
          description: 'erytrocyteDistribution-high',
          lowerLimit: 14.5,
          lowerLimitOperator: '>',
          upperLimit: 18.0,
          upperLimitOperator: '',
          unit: '%',
          interpretation: 'high',
          displayInterpretation: 'High',
        },
      ],
    },
    {
      name: 'meanCorpuscularHemoglobinContent',
      displayName: 'Corpuscular Hemoglobin Content',
      id: "meanCorpuscularHemoglobinContent",
      labReportName: 'Mittlerer korpuskulärer Hämoglobingehalt [MCH]',
      validFrom: '2021/01/05 1:00',
      validThrough: null,
      interpretationsSupported: [
        'deficiency',
        'normal',
        'high',
        'fault',
      ],
      ranges: [
        {
          color: '#ffffff',
          description: 'meanCorpuscularHemoglobinContent-deficiency',
          lowerLimit: 0,
          lowerLimitOperator: '>=',
          upperLimit: 28,
          upperLimitOperator: '<',
          unit: 'pg',
          interpretation: 'deficiency',
          displayInterpretation: 'Deficiency',
        },
        {
          color: '#ffffff',
          description: 'meanCorpuscularHemoglobinContent-normal',
          lowerLimit: 28,
          lowerLimitOperator: '>=',
          upperLimit: 34,
          upperLimitOperator: '<=',
          unit: 'pg',
          interpretation: 'normal',
          displayInterpretation: 'Normal',
        },
        {
          color: '#ffffff',
          description: 'meanCorpuscularHemoglobinContent-high',
          lowerLimit: 34,
          lowerLimitOperator: '>',
          upperLimit: 40,
          upperLimitOperator: '',
          unit: 'pg',
          interpretation: 'high',
          displayInterpretation: 'High',
        },
      ],
    },
    {
      name: 'meanCorpuscularVolume',
      displayName: 'Corpuscular Volume',
      id: 'meanCorpuscularVolume',
      labReportName: 'Mittleres korpuskuläres Volumen [MCV]',
      validFrom: '2021/01/05 1:00',
      validThrough: null,
      interpretationsSupported: [
        'deficiency',
        'normal',
        'high',
        'fault',
      ],
      ranges: [
        {
          color: '#ffffff',
          description: 'meanCorpuscularVolume-deficiency',
          lowerLimit: 0,
          lowerLimitOperator: '>=',
          upperLimit: 78,
          upperLimitOperator: '<',
          unit: 'fl',
          interpretation: 'deficiency',
          displayInterpretation: 'Deficiency',
        },
        {
          color: '#ffffff',
          description: 'meanCorpuscularVolume-normal',
          lowerLimit: 78,
          lowerLimitOperator: '>=',
          upperLimit: 94,
          upperLimitOperator: '<=',
          unit: 'fl',
          interpretation: 'normal',
          displayInterpretation: 'Normal',
        },
        {
          color: '#ffffff',
          description: 'meanCorpuscularVolume-high',
          lowerLimit: 94,
          lowerLimitOperator: '>',
          upperLimit: 110,
          upperLimitOperator: '',
          unit: 'fl',
          interpretation: 'high',
          displayInterpretation: 'High',
        },
      ],
    },
    {
      name: 'meanCorpuscularHemoglobinConcentration',
      displayName: 'Corpuscular Hemoglobin Concentration',
      id: 'meanCorpuscularHemoglobinConcentration',
      labReportName: 'Mean Corpuscular Hemoglobin Concentration [MCHC]',
      validFrom: '2021/01/05 1:00',
      validThrough: null,
      interpretationsSupported: [
        'deficiency',
        'normal',
        'high',
        'fault',
      ],
      ranges: [
        {
          color: '#ffffff',
          description: 'meanCorpuscularHemoglobinConcentration-deficiency',
          lowerLimit: 0,
          lowerLimitOperator: '>=',
          upperLimit: 300,
          upperLimitOperator: '<',
          unit: 'g/l',
          interpretation: 'deficiency',
          displayInterpretation: 'Deficiency',
        },
        {
          color: '#ffffff',
          description: 'meanCorpuscularHemoglobinConcentration-normal',
          lowerLimit: 300,
          lowerLimitOperator: '>=',
          upperLimit: 360,
          upperLimitOperator: '<=',
          unit: 'g/l',
          interpretation: 'normal',
          displayInterpretation: 'Normal',
        },
        {
          color: '#ffffff',
          description: 'meanCorpuscularHemoglobinConcentration-high',
          lowerLimit: 360,
          lowerLimitOperator: '>',
          upperLimit: 420,
          upperLimitOperator: '',
          unit: 'g/l',
          interpretation: 'high',
          displayInterpretation: 'High',
        },
      ],
    },
    {
      name: 'thrombocyteCount',
      displayName: 'Thrombocyte Count',
      id: 'thrombocyteCount',
      labReportName: 'Thrombozytenzahl [PLT]',
      validFrom: '2021/01/05 1:00',
      validThrough: null,
      interpretationsSupported: [
        'deficiency',
        'normal',
        'high',
        'fault',
      ],
      ranges: [
        {
          color: '#ffffff',
          description: 'thrombocyteCount-deficiency',
          lowerLimit: 0,
          lowerLimitOperator: '>=',
          upperLimit: 150,
          upperLimitOperator: '<',
          unit: 'G/l',
          interpretation: 'deficiency',
          displayInterpretation: 'Deficiency',
        },
        {
          color: '#ffffff',
          description: 'thrombocyteCount-normal',
          lowerLimit: 150,
          lowerLimitOperator: '>=',
          upperLimit: 400,
          upperLimitOperator: '<=',
          unit: 'G/l',
          interpretation: 'normal',
          displayInterpretation: 'Normal',
        },
        {
          color: '#ffffff',
          description: 'thrombocyteCount-high',
          lowerLimit: 400,
          lowerLimitOperator: '>',
          upperLimit: 500,
          upperLimitOperator: '',
          unit: 'G/l',
          interpretation: 'high',
          displayInterpretation: 'High',
        },
      ],
    },
    {
      name: 'meanThrombocyteVolume',
      displayName: 'Thrombocyte Volume',
      id: 'thrombocyteVolume',
      labReportName: 'Mittleres Thrombozytenvolumen [MPV]',
      validFrom: '2021/01/05 1:00',
      validThrough: null,
      interpretationsSupported: [
        'deficiency',
        'normal',
        'high',
        'fault',
      ],
      ranges: [
        {
          color: '#ffffff',
          description: 'meanThrombocyteVolume-deficiency',
          lowerLimit: 0,
          lowerLimitOperator: '>=',
          upperLimit: 7,
          upperLimitOperator: '<',
          unit: 'fl',
          interpretation: 'deficiency',
          displayInterpretation: 'Deficiency',
        },
        {
          color: '#ffffff',
          description: 'meanThrombocyteVolume-normal',
          lowerLimit: 7,
          lowerLimitOperator: '>=',
          upperLimit: 12,
          upperLimitOperator: '<=',
          unit: 'fl',
          interpretation: 'normal',
          displayInterpretation: 'Normal',
        },
        {
          color: '#ffffff',
          description: 'meanThrombocyteVolume-high',
          lowerLimit: 12,
          lowerLimitOperator: '>',
          upperLimit: 15,
          upperLimitOperator: '',
          unit: 'fl',
          interpretation: 'high',
          displayInterpretation: 'High',
        },
      ],
    },
  ],
};


export default smallBloodCount;