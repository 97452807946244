import * as React from 'react';
import { Datagrid, List, ListProps, TextField, useLocale } from 'react-admin';
import EmptyList from '../../../components/EmptyList';
import Paginator from '../../../components/Paginator';
import { MASS_TESTS_MODULE } from '../../../constants/apiUrls';
import { TESTS_PER_PAGE } from '../../../constants/pagination';
import messages from '../messages';

const postRowStyle = (record: any) => {
  let backgroundColor = 'white';

  if (record.hasReasonForCallback) {
    backgroundColor = 'rgb(255, 232, 124)';
  }

  if (new Date(record.dueDate) < new Date()) {
    backgroundColor = 'rgb(255, 92, 92)';
  }

  if (record.status === 'complete') {
    backgroundColor = '#efe';
  }

  return {
    backgroundColor,
  };
};

const PrescriptionList = (props: ListProps) => {
  const locale = useLocale();

  return (
    <List
      {...props}
      className="clients-list"
      bulkActionButtons={false}
      empty={
        <EmptyList
          url={MASS_TESTS_MODULE}
          message="No prescriptions yet"
          label=""
        />
      }
      actions={false}
      pagination={<Paginator perPage={TESTS_PER_PAGE} />}
      perPage={TESTS_PER_PAGE}
      {...props}
    >
      <Datagrid rowStyle={postRowStyle} rowClick="show">
        <TextField
          sortable={false}
          emptyText="-"
          source={`typeOfRequest.${locale}`}
          label={messages.typeOfRequest}
        />
        <TextField
          sortable={false}
          emptyText="-"
          source={`healthConcern.${locale}`}
          label={messages.healthConcern}
        />
        <TextField
          sortable={false}
          emptyText="-"
          source="typeOfMedication"
          label={messages.typeOfMedication}
        />
        <TextField
          sortable={false}
          emptyText="-"
          source={`orderType.${locale}`}
          label={messages.orderType}
        />
        <TextField
          sortable={false}
          emptyText="-"
          source={`state.${locale}`}
          label={messages.status}
        />
        <TextField
          sortable={false}
          emptyText="-"
          source="dueDate"
          label={messages.due}
        />
      </Datagrid>
    </List>
  );
};

export default PrescriptionList;
