import React from 'react';
import { Typography } from '@material-ui/core';

const IT = () => (
  <>
    <Typography variant="body2">
      <br />
      <br />
      Ciao!
      <br />
      <br />
      Ti è stato assegnato un test di laboratorio da fare a casa da{' '}
      <b>santé24</b>.
      <br />
      <br />
      Procedi come segue:
      <br />
      <br />
      <b>1.Ordina il test</b>
      <br />
      Clicca sul pulsante &quot;Ordina&quot; in fondo a questa email e segui le
      istruzioni per completare il processo di ordinazione. Nel corso del
      processo non sarà necessario effettuare un pagamento, poiché addebitiamo
      direttamente la tua assicurazione.
      <br />
      <br />
      <b>2. Effettua comodamente il test a casa tua</b>
      <br />
      1-2 giorni lavorativi dopo l&apos;ordine, riceverai il tuo kit di test
      consegnato a casa in una busta discreta. Il kit di test conterrà un codice
      di attivazione e tutti i materiali necessari, oltre a istruzioni facili da
      seguire per prelevare il campione. Successivamente, inserisci il tuo test
      nella scatola di ritorno fornita e depositala in qualsiasi buca delle
      lettere della Posta svizzera.
      <br />
      <br />
      <b>3. Ricevi i risultati</b>
      <br />
      Il tuo campione sarà analizzato da Medisyn, un prestigioso fornitore di
      laboratori svizzeri. Una volta pronti i risultati, riceverai una chiamata
      da santé24 per discuterne. Dopo la chiamata, i risultati saranno resi
      disponibili online.
    </Typography>
    <button type="button" className="sidebar__button">
      Commander
    </button>
    <Typography variant="body2">
      Se hai domande, puoi contattarci in qualsiasi momento all&apos;indirizzo
      service@yourself.health!
      <br />
      <br />
      Un caro saluto,
      <br />
      <br />
      Il team di Health Yourself
      <br />
      <br />
      Lerchenfeldstrasse 3 <br />
      9014 St. Gallen
    </Typography>
  </>
);

export default IT;
