import React from 'react';
import { RowForm } from '@react-admin/ra-editable-datagrid';

import { useRefresh } from 'react-admin';
import RowFormData from '../../models/rowFormData';
import getInputByType from '../../utils/helpers/getInputByType';
import useRowSave from '../../hooks/useRowSave';
import { MASS_TESTS_MODULE } from '../../constants/apiUrls';

const FIELDS_NOT_TO_EDIT = ['resultState'];
const TestedPersonsRowForm = ({
  rows,
  firstId,
  record,
  id,
  ...props
}: {
  rows: RowFormData[];
  firstId: string;
  id?: string;
  record?: Record<string, string>;
}) => {
  const onSave = useRowSave('update', `${MASS_TESTS_MODULE}`, `${firstId}`);
  const refresh = useRefresh();
  return (
    <RowForm
      {...props}
      save={(data) => {
        onSave({ ...data, id }).then(() => setTimeout(() => refresh(), 1500));
      }}
    >
      {rows
        .filter((row) => !FIELDS_NOT_TO_EDIT.includes(row.database))
        .map((input, i) =>
          getInputByType(input.dataType, {
            label: input.friendlyName,
            key: `input ${i}`,
            source: input.database,
            initialValue: record?.[input.database],
            disabled:
              record?.state === 'synced' && !input.canBeEditWithSyncState,
          }),
        )}
      {/* <TextInput
        label="Overall Result"
        source="overallResult"
        key="overallResult"
        {...props}
      /> */}
    </RowForm>
  );
};
export default TestedPersonsRowForm;
