import { useCallback } from 'react';
import { useMutation } from 'react-admin';

const useCustomCreate = (type: string) => {
  const [mutate] = useMutation();
  const update = useCallback(
    (resource, id, data, previousData, options) => {
      if (typeof resource === 'string') {
        const query = {
          type,
          resource,
          payload: {
            id,
            data,
            previousData,
          },
        };
        return mutate(query, options)?.catch(({ response }: any) => {
          if (response?.data) {
            return response.data;
          }
          return {};
        });
      }
      return undefined;
    },
    [mutate],
  );
  return update;
};
export default useCustomCreate;
