export const BASE_ADMIN_URL = `${process.env.REACT_APP_BASE_URL}`;
export const BASE_AUTH_URL = `${process.env.REACT_APP_BASE_URL}/auth`;
export const MASS_TESTS_MODULE = 'mass-tests';
export const BARCODE_MODULE = 'barcodes-filling';
export const IS_TEST_UNIQUE_URL = `${MASS_TESTS_MODULE}/is-test-unique`;
export const APPROVE_DRAFT_URL = `${MASS_TESTS_MODULE}/approve-draft`;
export const GET_FORM_CHOICES_URL = `bulk-tests-form-choices`;
export const GET_FORM_CHOICES_SAMPLING_URL = `${GET_FORM_CHOICES_URL}/labs`;
export const GET_BARCODE_CHOICES_URL = 'bar-codes-form-choices';
export const BARCODE_PERSONS_URL = 'barcode-filling';
export const PRESCRIPTIONS_MODULE = 'prescription';
export const USERS_MODULE = 'auth';
export const SIGN_UP_URL = `${USERS_MODULE}/sign-up`;
export const VIDEO_REVIEW_MODULE = 'video-review';
export const TESTS_MODULE='tests/doctorReportNeededStateTests';
export const REVIEWED_TESTS_MODULE='tests/reviewed-tests';
export const TEST_ASSIGNMENT_MODULE = 'test-assignments';
export const IMMUNOTHERAPY_MODULE = 'tests/immunotherapy';
export const REVIEWED_IMMUNOTHERAPY_MODULE = 'tests/reviewed-immunotherapy';
