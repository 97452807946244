import React from 'react';
import { Typography } from '@material-ui/core';

const DE = () => (
  <>
    <Typography variant="body2">
      <br />
      <br />
      Hallo!
      <br />
      <br />
      Dir wurde von santé24 ein Labortest von zu Hause zugewiesen.
      <br />
      <br />
      Bitte geh wie folgt vor:
      <br />
      <br />
      <b>1. Test bestellen</b>
      <br />
      Klicke auf den &quot;Bestellen&quot; Knopf am Ende dieser Email und folge
      den Anweisungen um den Bestellprozess abzuschliessen. Im Rahmen des
      Prozesses ist keine Zahlung erforderlich, da wir die Leistung direkt bei
      deiner Versicherung in Rechnung stellen.
      <br />
      <br />
      <b>2. Bequem zuhause deinen Test durchführen</b>
      <br />
      1-2 Werktage nach der Bestellung erhälst du dein Testkit in einem
      diskreten Umschlag per Post nach Hause geliefert. Das Testkit enthält
      einen Aktivierungscode sowie alle Materialien und leicht verständliche
      Anweisungen zur Entnahme deiner Probe. Anschliessend verpackst du deine
      Probe in der mitgelieferten Rücksendebox und wirfst sie in einen
      beliebigen Briefkasten der Schweizer Post.
      <br />
      <br />
      <b>3. Ergebnisse erhalten</b>
      <br />
      Deine Probe wird von Medisyn, einem führenden Schweizer
      Labordienstleister, analysiert. Sobald deine Ergebnisse vorliegen,
      erhältst du einen Anruf von santé24, um sie zu besprechen. Nach dem Anruf
      werden dir die Ergebnisse online zur Verfügung gestellt.
    </Typography>
    <button type="button" className="sidebar__button">
      Bestellen
    </button>
    <Typography variant="body2">
      Solltest du Fragen haben, kannst du uns jederzeit unter
      service@yourself.health kontaktieren!
      <br />
      <br />
      Viele Grüsse,
      <br />
      <br />
      das Health Yourself Team
      <br />
      <br />
      Lerchenfeldstrasse 3 <br />
      9014 St. Gallen
    </Typography>
  </>
);

export default DE;
