const antiHBC = {
  hidden: true,
  parameters: [
    {
      name: 'antiHBC',
      id: 'antiHBC',
      displayName: '',
      labReportName: 'Hepatitis B - Anti-HBHepatitis B - Anti-HBcc',
      validFrom: '2022/01/01 0:00',
      validThrough: null,
      stringValuesSupported: [
        'positive',
        'negative',
        'fault',
      ],
      outcomes: [{
        color: '#1f7359',
        description: 'antiHBC-negative',
        name: 'negative',
        displayInterpretation: 'Negative'
      },
      {
        color: '#f52d0a',
        description: 'antiHBC-positive',
        name: 'positive',
        displayInterpretation: 'Positive',
      },
      {
        color: '#000000',
        description: 'antiHBC-fault',
        name: 'fault',
        displayInterpretation: 'Fault',
      }],
    },
  ],
};

export default antiHBC;
