import React, { useEffect } from 'react';
import { useFormState } from 'react-final-form';

const FormContent = ({ setFormValues }: { setFormValues: any }) => {
  const formState = useFormState({ subscription: { values: true } });

  useEffect(() => {
    setFormValues(formState.values);
  }, [formState]);
  return <span />;
};

export default FormContent;
