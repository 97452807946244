import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ListProps,
  CreateButton,
  useTranslate,
} from 'react-admin';
import EmptyList from '../../../components/EmptyList';
import TestTypeField from '../../../components/Fields/TestTypeField';
import { TEST_ASSIGNMENT_MODULE } from '../../../constants/apiUrls';
import ActionsList from './ActionsList';

import './styles.scss';

const TestAssignmentList = (props: ListProps) => {
  const translate = useTranslate();
  return (
    <List
      {...props}
      bulkActionButtons={false}
      actions={<ActionsList />}
      empty={
        <EmptyList
          url={TEST_ASSIGNMENT_MODULE}
          message={translate('ra.message.test_assignment_empty_list')}
          label="ra.action.assign_new_test"
        >
          <CreateButton
            label="ra.action.assign_new_test"
            size="medium"
            variant="contained"
            color="secondary"
          />
        </EmptyList>
      }
      className="test-assignment-list"
      pagination={false}
    >
      <Datagrid>
        <TextField source="email" sortable={false} label="tests.email" />
        <TestTypeField source="testType" label="tests.type" />
        <DateField
          source="formatedDate"
          label="ra.label.date_assigned"
          sortable={false}
        />
      </Datagrid>
    </List>
  );
};

export default TestAssignmentList;
