import React from 'react';
import './styles.scss';

const SectionTitle = ({
  title,
  className,
}: {
  title: string;
  className?: string;
}) => (
  <div className={`wrapper ${className}`}>
    <h2 className="title">{title}</h2>
  </div>
);

export default SectionTitle;
