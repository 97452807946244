import * as React from 'react';
import {
  EditRowButton,
  DeleteWithConfirmIconButton,
} from '@react-admin/ra-editable-datagrid';
import { MASS_TESTS_MODULE } from '../../constants/apiUrls';

const ActionsColumn = ({ noDelete, path }: ActionsColumnProp) => (
  <>
    <EditRowButton />
    {!noDelete && (
      <DeleteWithConfirmIconButton
        resource={path}
        redirect={false}
        basePath={MASS_TESTS_MODULE}
      />
    )}
  </>
);
export interface ActionsColumnProp {
  undoable?: boolean;
  noDelete?: boolean;
  redirect: string | boolean;
  [key: string]: any;
}

export default ActionsColumn;
