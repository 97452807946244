const reactionOptions = ['weak', 'moderate', 'strong', 'veryStrong'];

const allAllergens = [
  'acacia',
  'acarus-siro',
  'alder',
  'almond',
  'alternaria-alternata',
  'american-cockroach',
  'american-house-dust-mite',
  'anisakis-simplex',
  'anise',
  'annual-mercury',
  'apple',
  'arizona-cypress',
  'ash',
  'aspergillus-fumigatus',
  'atlantic-cod',
  'atlantic-herring',
  'atlantic-mackerel',
  'avocado',
  'bahia-grass',
  'bakers-yeast',
  'banana',
  'barley',
  'beech',
  'beef',
  'bermuda-grass',
  'black-tiger-shrimp',
  'blomia-tropicalis',
  'blueberry',
  'brazil-nut',
  'brown-shrimp',
  'buckwheat',
  'camels-milk',
  'caraway',
  'carott',
  'carp',
  'cashew',
  'cat',
  'cattle',
  'celery',
  'cherry',
  'chicken',
  'chickpea',
  'cladosporium-herbarum',
  'common-mussel',
  'common-reed',
  'common-wasp-venom',
  'corn-cereal',
  'cottonwood',
  'cows-milk',
  'crab',
  'cultivated-rye',
  'cypress',
  'date-palm',
  'djungarian-hamster',
  'dog',
  'dog-male-urine',
  'egg-white',
  'egg-yolk',
  'elm',
  'european-house-dust-mite',
  'fenugreek-seeds',
  'fig',
  'fire-ant-venom',
  'garlic',
  'german-cockroach',
  'glycyphagus-domesticus',
  'goat',
  'goats-milk',
  'grape',
  'green-bean',
  'guinea-pig',
  'hazel',
  'hazelnut',
  'hemp',
  'hom-s-lactoferrin',
  'honey-bee-venom',
  'horse',
  'house-cricket',
  'kiwi',
  'lamb',
  'lambs-quarter',
  'latex',
  'lentil',
  'lepidoglyphus-destructor',
  'lobster',
  'london-plane-tree',
  'long-headed-wasp-venom',
  'lupine-seed',
  'macadamia',
  'malassezia-sympodialis',
  'mango',
  'mares-milk',
  'mealworm',
  'migratory-locust',
  'millet',
  'mountain-cedar',
  'mouse',
  'mugwort',
  'mulberry-tree',
  'muskmelon',
  'mustard',
  'nettle',
  'northern-prawn',
  'oat',
  'olive',
  'onion',
  'orange',
  'oregano',
  'oyster',
  'papaya',
  'paper-mulberry',
  'paper-wasp-venom',
  'paprika',
  'parsley',
  'pea',
  'peach',
  'peanut',
  'pear',
  'pecan',
  'penicilium-chrysogenum',
  'perennial-ryegrass',
  'pig',
  'pigeon-tick',
  'pigweed',
  'poppy-seed',
  'potato',
  'pumpkin-seed',
  'quinoa',
  'rabbit',
  'ragweed',
  'rat',
  'ribwort',
  'rice',
  'russian-thistle',
  'rye',
  'salmon',
  'scallop',
  'sesame',
  'sheep',
  'sheeps-milk',
  'shrimp-mix',
  'silver-birch',
  'soy',
  'spelt',
  'squid',
  'strawberry',
  'sugi',
  'sunflower-seed',
  'swordfish',
  'thornback-ray',
  'timothy',
  'tomato',
  'tree-of-heaven',
  'tuna',
  'turkey',
  'tyrophagus-putrescentiae',
  'venus-clam',
  'wall-pellitory',
  'walnut',
  'weeping-fig',
  'wheat'
];

const initialAllergens = [
  { allergen: 'hazel', allergyConfirmed: false, allergicReactionStrength: '' },
  { allergen: 'ash', allergyConfirmed: false, allergicReactionStrength: '' },
  { allergen: 'silver-birch', allergyConfirmed: false, allergicReactionStrength: '' },
  { allergen: 'grass', allergyConfirmed: false, allergicReactionStrength: '' },
  { allergen: 'ragweed', allergyConfirmed: false, allergicReactionStrength: '' },
  { allergen: 'cat', allergyConfirmed: false, allergicReactionStrength: '' },
  { allergen: 'european-house-dust-mite', allergyConfirmed: false, allergicReactionStrength: '' },
];


export { reactionOptions, allAllergens, initialAllergens };