enum S24ParameterNames {
  transferrinSaturation = 'transferrinSaturation',
  tsh = 'tshSante24',
  ferritin ='ferritinSante24',
  crp ='crp',
  smallBloodCount = 'smallBloodCount',
};

const getParameterNameForS24 = (parameterName: string) => S24ParameterNames[parameterName as keyof typeof S24ParameterNames];

// This function should calculate if age of a person is between 14-18 or 19-120 taking
// in consideration in what month person is born so it has to be full year and return age range
const getAgeRange = (birthOfDate: string): string => { // birthOfDate is in format YYYY-MM-DD
  const birthYear = birthOfDate.split('-')[0] as unknown as number;
  const birthMonth = birthOfDate.split('-')[1] as unknown as number;
  const birthDay = birthOfDate.split('-')[2] as unknown as number;
  const today = new Date();
  const todayYear = today.getFullYear();
  const todayMonth = today.getMonth() + 1;
  const todayDay = today.getDate();
  let age = todayYear - birthYear;
  if (todayMonth < birthMonth) {
    age -= 1;
  }
  if (todayMonth === birthMonth && todayDay < birthDay) {
    age -= 1;
  }
  if (age >= 14 && age <= 18) {
    return '14-18';
  }
  if (age >= 19 && age <= 120) {
    return '19-120';
  }
  return '0';
};

const getParametersForS24 = (parameters: any, userInfo: any) => parameters.map((parameter: any) => {
  // Since ferritin has names like 'ferritin-all-5-18', we need to add 'sante24-' to the name
  let parameterName = parameter.name;
  if (parameterName.includes('ferritin')) {
    parameterName = `sante24-${parameterName}`;
  }
  if (parameterName.includes('TSH')) {
    const ageRange = getAgeRange(userInfo.dateOfBirth);
    if (ageRange === '0') {
      parameterName = 'error';
    }

    parameterName = `sante24-${parameterName}-all-${ageRange}`;
  }
  return {
    ...parameter,
    name: parameterName,
  };
});

export {
  getParameterNameForS24,
  getParametersForS24,
};
