export default {
  typeOfRequest: 'prescription.typeOfRequest',
  healthConcern: 'prescription.healthConcern',
  typeOfMedication: 'prescription.typeOfMedication',
  orderType: 'prescription.orderType',
  status: 'prescription.status',
  due: 'prescription.due',
  lastName: 'prescription.lastName',
  firstName: 'prescription.firstName',
  dateOfBirth: 'prescription.dateOfBirth',
  street: 'prescription.street',
  town: 'prescription.town',
  postalCode: 'prescription.postalCode',
  documents: 'prescription.documents',
  videoForReview: 'prescription.videoForReview',
  actionsToTake: 'prescription.actionsToTake',
  reviewCriteria: 'prescription.reviewCriteria',
  identityVerified: 'prescription.identityVerified',
  noHealthRisks: 'prescription.noHealthRisks',
  previousUse: 'prescription.previousUse',
  rejectionReason: 'prescription.rejectionReason',
  approve: 'prescription.approve',
  submit: 'prescription.submit',
  reject: 'prescription.reject',
  proofOfPreviousUse: 'prescription.proofOfPreviousUse',
  contact: 'prescription.contact',
  email: 'prescription.email',
  phone: 'prescription.phone',
  contactCustomer: 'prescription.contactCustomer',
  freeText: 'prescription.freeText',
  approval: 'prescription.approval',
  packageSize: 'prescription.packageSize',
  numberOfPackages: 'prescription.numberOfPackages',
  billingAddress: 'prescription.billingAddress',
  shippingAddress: 'prescription.shippingAddress',
  medicine: 'prescription.medicine',
  itemName: 'prescription.itemName',
  type: 'prescription.type',
  reasonForCallback: 'prescription.reasonForCallback',
  callbackCompleted: 'prescription.callbackCompleted',
  callbackTime: 'prescription.callbackTime',
  GTIN: 'prescription.GTIN',
};
