import React from 'react';
import { useTranslate } from 'react-admin';

import './styles.scss';

type DoctorsReportProps = {
  doctorsReport: string;
  doctorsName: string;
};

const DoctorsReport: React.FunctionComponent<DoctorsReportProps> = ({
  doctorsReport,
  doctorsName,
}) => {
  const translate = useTranslate();
  return (
    <div className="doctors-report">
      <div className="doctors-report__name-wrapper">
        <span className="doctors-report__label">
          {translate('tests.doctorName')}:
        </span>
        <span>{doctorsName}</span>
      </div>
      <div className="doctors-report__report">
        <span className="doctors-report__label">
          {translate('tests.report')}:
        </span>
        <span className="doctors-report__report">{doctorsReport}</span>
      </div>
    </div>
  );
};

export default DoctorsReport;
