const hepatitisB = {
  virtual: true,
  parameters: [
    {
      name: 'hepatitisB',
      displayName: 'Hepatitis B',
      id: 'hepatitisB',
      labReportName: 'Not on the lab report - calculate manually!',
      validFrom: '2022/01/01 0:00',
      validThrough: null,
      stringValuesSupported: [
        'positive',
        'acute',
        'unclear',
        'negative',
        'fault',
      ],
      testResultInstructions: [
        'positive - hbsAntigen: positive, antiHBC: positive',
        'acute - hbsAntigen: positive, antiHBC: negative',
        'unclear - hbsAntigen: negative, antiHBC: positive',
        'negative - hbsAntigen: negative, antiHBC: negative',
        'fault',
      ],
      outcomes: [{
        color: '#1f7359',
        description: 'hepatitisB-negative',
        name: 'negative',
        displayInterpretation: 'Negative',
      },
      {
        color: '#ffffff',
        description: 'hepatitisB-unclear',
        name: 'unclear',
        displayInterpretation: 'Unclear',
      },
      {
        color: '#f52d0a',
        description: 'hepatitisB-acute',
        name: 'acute',
        displayInterpretation: 'Acute',
      },
      {
        color: '#f52d0a',
        description: 'hepatitisB-positive',
        name: 'positive',
        displayInterpretation: 'Positive',
      },
      {
        color: '#000000',
        description: 'hepatitisB-fault',
        name: 'fault',
        displayInterpretation: 'Fault',
      }],
    },
  ],
};

export default hepatitisB;
