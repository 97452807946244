import React from 'react';
import { FieldProps, useRecordContext } from 'react-admin';

const VideoField = (props: FieldProps) => {
  const record = useRecordContext(props);
  const { videoForReview } = record;
  return (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <video src={`${videoForReview?.[0]}`} controls width="320" height="240">
      Sorry, your browser doesn&apos;t support embedded videos.
    </video>
  );
};
export default VideoField;
