import React from 'react';
import { SaveButton, Button, Toolbar, ToolbarProps } from 'react-admin';
import messages from '../../containers/Prescriptions/messages';

const PrescriptionEditToolbar = (
  props: ToolbarProps & {
    onReject?: () => void;
    isReject?: boolean;
    statusCompleted?: boolean;
  },
) => {
  const toolbarProps = { ...props };
  const { invalid, pristine } = props;
  const { onReject, isReject, statusCompleted, ...rest } = toolbarProps;
  return (
    <Toolbar {...rest}>
      <SaveButton
        disabled={invalid || pristine || statusCompleted}
        label={isReject ? messages.submit : messages.approve}
        submitOnEnter
        // eslint-disable-next-line react/jsx-no-useless-fragment
        icon={<></>}
      />
      {!isReject && !statusCompleted && (
        <Button
          onClick={onReject}
          style={{ marginLeft: '15px' }}
          size="medium"
          label={messages.reject}
          submitOnEnter={false}
        />
      )}
    </Toolbar>
  );
};

export default PrescriptionEditToolbar;
