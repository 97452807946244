import React, { useCallback, useEffect, useState } from 'react';
import {
  Edit,
  SimpleForm,
  useLocale,
  ReferenceManyField,
  useTranslate,
  ShowProps,
} from 'react-admin';
import { Typography } from '@material-ui/core';
import { MASS_TESTS_MODULE } from '../../../constants/apiUrls';
import GridFields from '../../../models/gridFields';
import { getGridFields } from '../../../utils/requests';
import TestedPersonsGrid from '../../../components/TestedPersonsGrid';
import GridListToolbar from '../../../components/GridListToolbar';
import messages from '../messages';
import DraftDataTable from '../../../components/DraftDataTable';
import SubmitApproveDialog from '../../../components/SubmitApproveDialog';
import useApproveDraft from '../../../hooks/useApproveDraft';

const DraftEditPage = ({ ...props }: ShowProps) => {
  const locale = useLocale();
  const translate = useTranslate();
  const [table, setFields] = useState<GridFields>();
  const [metainfo, setMetainfo] = useState<object>();
  const [isOpen, toggle] = useState(false);
  const [show, isShow] = useState(true);
  const pathname = props.history?.location.pathname.split('/')[2] || '';
  const id =
    pathname.match(/\d+_\d+_[\w | -]+/)?.[0] ||
    pathname.match(/\d+_[\w | -]+/)?.[0] ||
    pathname.match(/[\w | -]+/)?.[0] ||
    '1';
  const handleSave = useApproveDraft('update', MASS_TESTS_MODULE);
  useEffect(() => {
    getGridFields(locale, `${MASS_TESTS_MODULE}/${id}/fields`)
      .then(({ fields, record }: any) => {
        setFields(fields);
        setMetainfo(record);
      })
      .catch(() => {
        setFields([] as GridFields);
      });
  }, [locale]);

  return (
    <>
      <Typography variant="h2">{translate(messages.editPageTitle)}</Typography>
      <div className="grid-wrapper grid-wrapper-min">
        <DraftDataTable record={metainfo || {}} />
      </div>
      <Edit {...props} id={id}>
        <SimpleForm
          toolbar={
            <GridListToolbar
              clickHandler={useCallback(() => {
                if (show) {
                  toggle(true);
                } else {
                  handleSave({ id } as any);
                }
              }, [show])}
              {...(props as any)}
            />
          }
        >
          <SubmitApproveDialog
            open={isOpen}
            title="Form has errors. Approve?"
            handleConfirm={() => {
              handleSave({ id } as any);
            }}
            handleCancel={() => {
              toggle(false);
            }}
          />
          <ReferenceManyField
            fullWidth
            label="Tested Persons"
            reference="drafts"
            target={id}
          >
            <TestedPersonsGrid
              onError={() => {
                isShow(true);
              }}
              onClear={() => {
                isShow(false);
              }}
              idDraft={id}
              table={table}
              module={MASS_TESTS_MODULE}
            />
          </ReferenceManyField>
        </SimpleForm>
      </Edit>
    </>
  );
};

export default DraftEditPage;
