import React, { ChangeEvent, useState } from 'react';
import { Button, useTranslate } from 'react-admin';
import { Divider } from '@material-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';
import SectionTitle from '../../SectionTitle';
import CustomCheckbox from '../../CustomCheckbox';
import CustomSelect from '../../CustomSelect';
import CustomSelectWithTranslations from '../../CustomSelectWithTranslations';
import { reactionOptions, allAllergens } from './data';

import './styles.scss';

export interface Allergen {
  allergen: string;
  allergyConfirmed: boolean;
  allergicReactionStrength: string;
}

interface AllergensProps {
  defaultAllergens: Allergen[];
  additionalAllergens: Allergen[];
  setDefaultAllergens: React.Dispatch<React.SetStateAction<Allergen[]>>;
  setAdditionalAllergens: React.Dispatch<React.SetStateAction<Allergen[]>>;
  readonly?: boolean;
}

const Allergens: React.FC<AllergensProps> = ({
  additionalAllergens,
  setAdditionalAllergens,
  defaultAllergens,
  setDefaultAllergens,
  readonly,
}) => {
  const translate = useTranslate();
  const [allergenToAdd, setAllergenToAdd] = useState<string>();

  const handleAllergenToAddChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setAllergenToAdd(event.target.value);
  };

  const handleAddAllergen = () => {
    if (allergenToAdd) {
      setAdditionalAllergens([
        ...additionalAllergens,
        {
          allergen: allergenToAdd,
          allergyConfirmed: false,
          allergicReactionStrength: '',
        },
      ]);
    }
  };

  const handleAllergenChange = (
    type: 'default' | 'additional',
    index: number,
    field: string,
    event: ChangeEvent,
  ) => {
    let newValue: string | boolean | undefined;
    if (event.target instanceof HTMLSelectElement) {
      newValue = event.target.value;
    }
    if (event.target instanceof HTMLInputElement) {
      newValue = event.target.checked;
    }

    if (type === 'default') {
      setDefaultAllergens(
        defaultAllergens?.map((allergen, i) => {
          if (index === i) {
            return { ...allergen, [field]: newValue };
          }
          return allergen;
        }),
      );
    }
    if (type === 'additional') {
      setAdditionalAllergens(
        additionalAllergens?.map((allergen, i) => {
          if (index === i) {
            return { ...allergen, [field]: newValue };
          }
          return allergen;
        }),
      );
    }
  };

  const handleRemoveAllergen = (index: number) => {
    setAdditionalAllergens(
      additionalAllergens.filter((allergen, i) => i !== index),
    );
  };

  // If we don't have any allergens, we don't render the component
  if (
    readonly &&
    (!defaultAllergens ||
      (defaultAllergens && defaultAllergens.length === 0)) &&
    (!additionalAllergens ||
      (additionalAllergens && additionalAllergens.length === 0))
  ) {
    return null;
  }

  return (
    <>
      <div className="formWrapper">
        <SectionTitle title={translate('immunotherapy.diagnosis.title')} />
        <div className="listHeader">
          <div className="label listHeaderItem">
            {translate('immunotherapy.diagnosis.allergenName')}
          </div>
          <div className="label listHeaderItem">
            {translate('immunotherapy.diagnosis.allergyConfirmed')}
          </div>
          <div className="label listHeaderItem">
            {translate('immunotherapy.diagnosis.allergicReactionStrength')}
          </div>
          <div className="label listHeaderItem" />
        </div>
        <div className="list">
          {defaultAllergens?.map((allergen, index) => (
            <div
              key={`allergen-${allergen.allergen}-${Math.random()}`}
              className="listItem"
            >
              <div className="listItemContent">
                {translate(`immunotherapy.allergens.${allergen.allergen}`)}
              </div>
              <div className="listItemContent">
                <CustomCheckbox
                  label=""
                  disabled={readonly}
                  checked={allergen.allergyConfirmed}
                  onChange={(event) =>
                    handleAllergenChange(
                      'default',
                      index,
                      'allergyConfirmed',
                      event,
                    )
                  }
                />
              </div>
              <div className="listItemContent">
                {allergen.allergyConfirmed && (
                  <CustomSelect
                    emptyLabel={translate(
                      'immunotherapy.diagnosis.selectAllergicReactionStrength',
                    )}
                    disabled={readonly}
                    options={reactionOptions.map((reaction) => ({
                      value: reaction,
                      label: translate(`immunotherapy.diagnosis.${reaction}`),
                    }))}
                    value={allergen.allergicReactionStrength}
                    onChange={(event) =>
                      handleAllergenChange(
                        'default',
                        index,
                        'allergicReactionStrength',
                        event,
                      )
                    }
                    error={
                      allergen.allergyConfirmed &&
                      !allergen.allergicReactionStrength
                    }
                  />
                )}
              </div>
              <div className="listItemContent" />
            </div>
          ))}
        </div>
      </div>
      <Divider className="divider" />
      <div className="formWrapper">
        {additionalAllergens && additionalAllergens.length > 0 && (
          <>
            <div className="listHeader listHeader--additionalAllergens">
              <div className="label listHeaderItem">
                {translate('immunotherapy.diagnosis.allergenName')}
              </div>
              <div className="label listHeaderItem">
                {translate('immunotherapy.diagnosis.allergyConfirmed')}
              </div>
              <div className="label listHeaderItem">
                {translate('immunotherapy.diagnosis.allergicReactionStrength')}
              </div>
              <div className="label listHeaderItem">
                {translate('immunotherapy.diagnosis.deleteLabel')}
              </div>
            </div>
            <div className="list">
              {additionalAllergens?.map((allergen, index) => (
                <div key={`allergen-${allergen.allergen}`} className="listItem">
                  <div className="listItemContent">
                    {translate(`immunotherapy.allergens.${allergen.allergen}`)}
                  </div>
                  <div className="listItemContent">
                    <CustomCheckbox
                      disabled={readonly}
                      label=""
                      checked={allergen.allergyConfirmed}
                      onChange={(event) =>
                        handleAllergenChange(
                          'additional',
                          index,
                          'allergyConfirmed',
                          event,
                        )
                      }
                    />
                  </div>
                  <div className="listItemContent">
                    {allergen.allergyConfirmed && (
                      <CustomSelect
                        emptyLabel={translate(
                          'immunotherapy.diagnosis.selectAllergicReactionStrength',
                        )}
                        disabled={readonly}
                        options={reactionOptions.map((reaction) => ({
                          value: reaction,
                          label: translate(
                            `immunotherapy.diagnosis.${reaction}`,
                          ),
                        }))}
                        value={allergen.allergicReactionStrength}
                        onChange={(event) =>
                          handleAllergenChange(
                            'additional',
                            index,
                            'allergicReactionStrength',
                            event,
                          )
                        }
                        error={
                          allergen.allergyConfirmed &&
                          !allergen.allergicReactionStrength
                        }
                      />
                    )}
                  </div>
                  <div className="listItemContent">
                    {!readonly && (
                      <Button
                        className="deleteButton"
                        variant="text"
                        color="secondary"
                        onClick={() => handleRemoveAllergen(index)}
                      >
                        <DeleteIcon color="error" />
                      </Button>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
        {!readonly && (
          <>
            <h3 className="allergens__title">
              {translate('immunotherapy.diagnosis.additionalAllergen')}
            </h3>
            <div className="additionalAllergen">
              <CustomSelectWithTranslations
                emptyLabel={translate('immunotherapy.diagnosis.selectAllergen')}
                options={allAllergens.map((allergen) => ({
                  value: allergen,
                  label: allergen,
                }))}
                value={allergenToAdd}
                onChange={handleAllergenToAddChange}
                translatePath="immunotherapy.allergens"
              />
              <span className="extraMarginLeft">
                <Button
                  variant="contained"
                  label={`+ ${translate(
                    'immunotherapy.diagnosis.addAllergen',
                  )}`}
                  color="primary"
                  onClick={handleAddAllergen}
                />
              </span>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Allergens;
