const monthsMap = [
  { value: '1', label: 'January', shortLabel: 'Jan' },
  { value: '2', label: 'February', shortLabel: 'Feb' },
  { value: '3', label: 'March', shortLabel: 'Mar' },
  { value: '4', label: 'April', shortLabel: 'Apr' },
  { value: '5', label: 'May', shortLabel: 'May' },
  { value: '6', label: 'June', shortLabel: 'Jun' },
  { value: '7', label: 'July', shortLabel: 'Jul' },
  { value: '8', label: 'August', shortLabel: 'Aug' },
  { value: '9', label: 'September', shortLabel: 'Sep' },
  { value: '10', label: 'October', shortLabel: 'Oct' },
  { value: '11', label: 'November', shortLabel: 'Nov' },
  { value: '12', label: 'December', shortLabel: 'Dec' },
];

const allMedicines = [
  { id: 'itulazax', name: 'Itulazax', type: 'eyedrops' },
  { id: 'grazax', name: 'Grazax', type: 'pills' },
  { id: 'acarizax', name: 'Acarizax', type: 'nose-spray' },
]

export { monthsMap, allMedicines };
