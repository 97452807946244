import React from 'react';
import { Typography } from '@material-ui/core';

const EN = () => (
  <>
    <Typography variant="body2">
      <br />
      <br />
      Hello!
      <br />
      <br />
      You have been assigned a lab test from home by santé24.
      <br />
      <br />
      Please proceed as follows:
      <br />
      <br />
      <b>1. Order the test</b>
      <br />
      Click on the &quot;Order&quot; button below and go through the checkout
      process. No payment will be required as part of the process, we are
      billing your insurance company directly.
      <br />
      <br />
      <b>2. Take your test from the comfort of your home</b>
      <br />
      You will receive your test kit 1-2 working days after ordering. It will
      contain an activation code and all the material as well as easy-to-follow
      instructions for taking your sample. You then put your test in the
      provided return box and drop it off in any Swiss post mailbox.
      <br />
      <br />
      <b>3. Receive your results</b>
      <br />
      Your sample will be analysed by Medisyn, a leading Swiss laboratory
      provider. Once your results are ready, you will receive a call from
      santé24 to discuss them. After the call, the results will be made
      available to you online.
    </Typography>
    <button type="button" className="sidebar__button">
      Order Test
    </button>
    <Typography variant="body2">
      Should you have any questions or concerns, you can contact us under
      service@yourself.health.
      <br />
      <br />
      Kind regards,
      <br />
      <br />
      Team Health Yourself
      <br />
      <br />
      Lerchenfeldstrasse 3 <br />
      9014 St. Gallen
    </Typography>
  </>
);

export default EN;
