import React from 'react';
import { Show, ShowProps, useShowController } from 'react-admin';
import { Divider } from '@material-ui/core';

import UserDataBlock from '../../../components/UserDataBlock';
import ImmunotherapyAnswers from '../../../components/ImmunotherapyAnswers';
import ImmunotherapyForm from '../../../components/ImmonotherapyForm';

import './styles.scss';

const ImmunotherapyTestsShow = (props: ShowProps) => {
  const { record, error, loading } = useShowController(props);

  if (loading) {
    return <div>Loading</div>;
  }

  if (error) {
    return <div>Error {error}</div>;
  }

  return (
    <Show {...props} className="testShow">
      <>
        <UserDataBlock record={record} />
        <Divider className="divider" />
        <ImmunotherapyAnswers />
        <Divider className="divider" />
        <ImmunotherapyForm record={record} />
      </>
    </Show>
  );
};

export default ImmunotherapyTestsShow;
