const hdl = {
  parameters: [
    {
      name: 'HDL',
      displayName: 'HDL',
      id: "hdl",
      validFrom: '2022/05/24 1:00',
      validThrough: null,
      interpretationsSupported: [
        'normal',
        'deficiency',
        'fault',
      ],
      ranges: [{
        color: '#f52d0a',
        description: 'HDL-deficiency',
        lowerLimit: 0,
        lowerLimitOperator: '>=',
        upperLimit: 1,
        upperLimitOperator: '<=',
        unit: 'mmol/l',
        interpretation: 'deficiency',
        displayInterpretation: 'Deficiency',
      },
      {
        color: '#1f7359',
        description: 'HDL-normal',
        lowerLimit: 1,
        lowerLimitOperator: '>',
        upperLimit: 6,
        upperLimitOperator: '',
        unit: 'mmol/l',
        interpretation: 'normal',
        displayInterpretation: 'Normal',
      }],
    },
  ],
};

export default hdl;