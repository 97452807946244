import * as React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { LanguageSwitcher } from '@react-admin/ra-preferences';
import { AppBar, AppBarProps } from 'react-admin';

const useStyles = makeStyles({
  logo: {
    maxWidth: '160px',
    paddingTop: '5px',
    marginRight: '15px',
  },
  logoImg: {
    width: '100%',
  },
});
const CustomAppBar = (props: AppBarProps) => {
  const classes = useStyles();
  return (
    <AppBar {...props}>
      <div className={classes.logo}>
        <img
          alt="logo"
          src="images/logo_white.png"
          className={classes.logoImg}
        />
      </div>
      <Box flex="1">
        <Typography variant="h6" id="react-admin-title" />
      </Box>
      <LanguageSwitcher
        languages={[
          { locale: 'en', name: 'English' },
          { locale: 'de', name: 'Deutsch' },
          { locale: 'fr', name: 'Français' },
        ]}
        defaultLanguage="English"
      />
    </AppBar>
  );
};
export default CustomAppBar;
