const basalCortisol = {
  parameters: [
    {
      // Samples taken outside of the optimal time ranges (times at which cortisol values are most specific) don't get a range score and will be labeled with a warning that the meaning of the cortisol value can't be properly interpreted.
      name: 'basal-cortisol-warning',
      id: 'basalCortisolWarning',
      displayName: 'Basal Cortisol Warning',
      validFrom: '2022/03/07 1:00',
      validThrough: null,
      interpretationsSupported: [
        'deficiency',
        'normal',
        'too-high',
        'warning',
        'fault',
      ],
      criteria: {
        estimatedSamplingTime: {
          min: '00:00',
          max: '06:55',
        },
      },
      hl7: [
        'basalCortisolMorning',
      ],
      ranges: [{
        color: '#ffffff',
        description: 'basal-cortisol-warning',
        lowerLimit: 0,
        lowerLimitOperator: '>=',
        upperLimit: 1000,
        upperLimitOperator: '',
        unit: 'nmol/l',
        interpretation: 'warning',
        displayInterpretation: 'Warning',
        treatAs: 'normal',
      }],
    },
    {
      name: 'basal-cortisol-morning',
      displayName: 'Basal Cortisol Morning',
      id: 'basalCortisolMorning',
      validFrom: '2022/03/07 1:00',
      validThrough: null,
      interpretationsSupported: [
        'deficiency',
        'normal',
        'too-high',
        'fault',
      ],
      criteria: {
        estimatedSamplingTime: {
          min: '06:55',
          max: '08:01',
        },
      },
      hl7: [
        'basalCortisolMorning',
      ],
      ranges: [{
        color: '#F7941F',
        description: 'basal-cortisol-morning-deficiency',
        lowerLimit: 0,
        lowerLimitOperator: '>=',
        upperLimit: 101,
        upperLimitOperator: '<',
        unit: 'nmol/l',
        interpretation: 'deficiency',
        displayInterpretation: 'Deficiency',
      },
      {
        color: '#217359',
        description: 'basal-cortisol-morning-normal',
        lowerLimit: 101,
        lowerLimitOperator: '>=',
        upperLimit: 536,
        upperLimitOperator: '<=',
        unit: 'nmol/l',
        interpretation: 'normal',
        displayInterpretation: 'Normal',
      },
      {
        color: '#EF4036',
        description: 'basal-cortisol-morning-too-high',
        lowerLimit: 536,
        lowerLimitOperator: '>',
        upperLimit: 1000,
        upperLimitOperator: '',
        unit: 'nmol/l',
        interpretation: 'too-high',
        displayInterpretation: 'Too high',
      }],
    },
    {
      // Samples taken outside of the optimal time ranges (times at which cortisol values are most specific) don't get a range score and will be labeled with a warning that the meaning of the cortisol value can't be properly interpreted.
      name: 'basal-cortisol-warning',
      displayName: 'Basal Cortisol Warning',
      id: 'basalCortisolWarning',
      validFrom: '2022/03/07 1:00',
      validThrough: null,
      interpretationsSupported: [
        'deficiency',
        'normal',
        'too-high',
        'warning',
        'fault',
      ],
      criteria: {
        estimatedSamplingTime: {
          min: '08:01',
          max: '11:55',
        },
      },
      hl7: [
        'basalCortisolMorning',
      ],
      ranges: [{
        color: '#ffffff',
        description: 'basal-cortisol-warning',
        lowerLimit: 0,
        lowerLimitOperator: '>=',
        upperLimit: 1000,
        upperLimitOperator: '',
        unit: 'nmol/l',
        interpretation: 'warning',
        displayInterpretation: 'Warning',
        treatAs: 'normal',
      }],
    },
    {
      name: 'basal-cortisol-midday',
      displayName: 'Basal Cortisol Midday',
      id: 'basalCortisolMidday',
      validFrom: '2022/03/07 1:00',
      validThrough: null,
      interpretationsSupported: [
        'deficiency',
        'normal',
        'too-high',
        'fault',
      ],
      criteria: {
        estimatedSamplingTime: {
          min: '11:55',
          max: '13:01',
        },
      },
      hl7: [
        'basalCortisolMidday',
      ],
      ranges: [{
        color: '#F7941F',
        description: 'basal-cortisol-midday-deficiency',
        lowerLimit: 0,
        lowerLimitOperator: '>=',
        upperLimit: 96.6,
        upperLimitOperator: '<',
        unit: 'nmol/l',
        interpretation: 'deficiency',
        displayInterpretation: 'Deficiency',
      },
      {
        color: '#217359',
        description: 'basal-cortisol-midday-normal',
        lowerLimit: 96.6,
        lowerLimitOperator: '>=',
        upperLimit: 483,
        upperLimitOperator: '<=',
        unit: 'nmol/l',
        interpretation: 'normal',
        displayInterpretation: 'Normal',
      },
      {
        color: '#EF4036',
        description: 'basal-cortisol-midday-too-high',
        lowerLimit: 483,
        lowerLimitOperator: '>',
        upperLimit: 1000,
        upperLimitOperator: '',
        unit: 'nmol/l',
        interpretation: 'too-high',
        displayInterpretation: 'Too high',
      }],
    },
    {
      // Samples taken outside of the optimal time ranges (times at which cortisol values are most specific) don't get a range score and will be labeled with a warning that the meaning of the cortisol value can't be properly interpreted.
      name: 'basal-cortisol-warning',
      displayName: 'Basal Cortisol Warning',
      id: 'basalCortisolWarning',
      validFrom: '2022/03/07 1:00',
      validThrough: null,
      interpretationsSupported: [
        'deficiency',
        'normal',
        'too-high',
        'warning',
        'fault',
      ],
      criteria: {
        estimatedSamplingTime: {
          min: '13:01',
          max: '16:55',
        },
      },
      hl7: [
        'basalCortisolMidday',
      ],
      ranges: [{
        color: '#ffffff',
        description: 'basal-cortisol-warning',
        lowerLimit: 0,
        lowerLimitOperator: '>=',
        upperLimit: 1000,
        upperLimitOperator: '',
        unit: 'nmol/l',
        interpretation: 'warning',
        displayInterpretation: 'Warning',
        treatAs: 'normal',
      }],
    },
    {
      name: 'basal-cortisol-evening',
      displayName: 'Basal Cortisol Evening',
      id: 'basalCortisolEvening',
      validFrom: '2022/03/07 1:00',
      validThrough: null,
      interpretationsSupported: [
        'deficiency',
        'normal',
        'too-high',
        'fault',
      ],
      criteria: {
        estimatedSamplingTime: {
          min: '16:55',
          max: '18:01',
        },
      },
      hl7: [
        'basalCortisolEvening',
      ],
      ranges: [{
        color: '#F7941F',
        description: 'basal-cortisol-evening-deficiency',
        lowerLimit: 0,
        lowerLimitOperator: '>=',
        upperLimit: 79,
        upperLimitOperator: '<',
        unit: 'nmol/l',
        interpretation: 'deficiency',
        displayInterpretation: 'Deficiency',
      },
      {
        color: '#217359',
        description: 'basal-cortisol-evening-normal',
        lowerLimit: 79,
        lowerLimitOperator: '>=',
        upperLimit: 478,
        upperLimitOperator: '<=',
        unit: 'nmol/l',
        interpretation: 'normal',
        displayInterpretation: 'Normal',
      },
      {
        color: '#EF4036',
        description: 'basal-cortisol-evening-too-high',
        lowerLimit: 478,
        lowerLimitOperator: '>',
        upperLimit: 1000,
        upperLimitOperator: '',
        unit: 'nmol/l',
        interpretation: 'too-high',
        displayInterpretation: 'Too high',
      }],
    },
    {
      // Samples taken outside of the optimal time ranges (times at which cortisol values are most specific) don't get a range score and will be labeled with a warning that the meaning of the cortisol value can't be properly interpreted.
      name: 'basal-cortisol-warning',
      displayName: 'Basal Cortisol Warning',
      id: 'basalCortisolWarning',
      validFrom: '2022/03/07 1:00',
      validThrough: null,
      interpretationsSupported: [
        'deficiency',
        'normal',
        'too-high',
        'warning',
        'fault',
      ],
      criteria: {
        estimatedSamplingTime: {
          min: '18:01',
          max: '24:00',
        },
      },
      hl7: [
        'basalCortisolEvening',
      ],
      ranges: [{
        color: '#ffffff',
        description: 'basal-cortisol-warning',
        lowerLimit: 0,
        lowerLimitOperator: '>=',
        upperLimit: 1000,
        upperLimitOperator: '',
        unit: 'nmol/l',
        interpretation: 'warning',
        displayInterpretation: 'Warning',
        treatAs: 'normal',
      }],
    },
  ],
};

export default basalCortisol;