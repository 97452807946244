import React from 'react';
import {
  FileField,
  SimpleShowLayout,
  TextField,
  useTranslate,
} from 'react-admin';
import SectionTitle from '../SectionTitle';

import './styles.scss';

const ImmunotherapyAnswers = () => {
  const translate = useTranslate();

  return (
    <>
      <SectionTitle
        title={translate('immunotherapy.patientAnswers')}
        className="immunotherapyAnswers__title"
      />
      <div className="immunotherapyAnswers__column-wrapper">
        <SimpleShowLayout className="immunotherapyAnswers__column">
          <TextField
            className="immunotherapyAnswers__textField"
            source="answers.allergiesNames"
            label="immunotherapy.patient.allergiesNames"
            emptyText="-"
          />
          <TextField
            className="immunotherapyAnswers__textField"
            source="answers.otherAllergies"
            label="immunotherapy.patient.otherAllergies"
            emptyText="-"
          />
          <TextField
            className="immunotherapyAnswers__textField"
            source="answers.allergySymptomsExperienced"
            label="immunotherapy.patient.symptoms"
            emptyText="-"
          />
          <TextField
            className="immunotherapyAnswers__textField"
            source="answers.purposeOfTakingTest"
            label="immunotherapy.patient.purposeOfTakingTest"
            emptyText="-"
          />
          <TextField
            className="immunotherapyAnswers__textField"
            source="answers.symptomsTiming"
            label="immunotherapy.patient.symptomPeriod"
            emptyText="-"
          />
          <TextField
            className="immunotherapyAnswers__textField"
            source="answers.durationMonths"
            label="immunotherapy.patient.durationMonths"
            emptyText="-"
          />
          <TextField
            className="immunotherapyAnswers__textField"
            source="answers.sinceWhenAllergySymptomsOccur"
            label="immunotherapy.patient.symptomsOccurrenceYear"
            emptyText="-"
          />
          <TextField
            className="immunotherapyAnswers__textField"
            source="answers.howSymptomsEvolved"
            label="immunotherapy.patient.symptomsEvolution"
            emptyText="-"
          />
          <TextField
            className="immunotherapyAnswers__textField"
            source="answers.factorsThatWorsenTheSymptoms"
            label="immunotherapy.patient.symptomsWorseningFactors"
            emptyText="-"
          />
        </SimpleShowLayout>
        <SimpleShowLayout className="immunotherapyAnswers__column">
          <TextField
            className="immunotherapyAnswers__textField"
            source="answers.petsInHousehold"
            label="immunotherapy.patient.petsAtHome"
            emptyText="-"
          />
          <TextField
            className="immunotherapyAnswers__textField"
            source="answers.haveAsthma"
            label="immunotherapy.patient.asthma"
            emptyText="-"
          />
          <TextField
            className="immunotherapyAnswers__textField"
            source="answers.medicalConditions"
            label="immunotherapy.patient.preExistingConditions"
            emptyText="-"
          />
          <TextField
            className="immunotherapyAnswers__textField"
            source="answers.medicationName"
            label="immunotherapy.patient.medicationName"
            emptyText="-"
          />
          <TextField
            className="immunotherapyAnswers__textField"
            source="answers.immunotherapyTried"
            label="immunotherapy.patient.immunotherapyTried"
            emptyText="-"
          />
          <TextField
            className="immunotherapyAnswers__textField"
            source="answers.immunotherapyForWhichAllergens"
            label="immunotherapy.patient.immunotherapyForWhichAllergens"
            emptyText="-"
          />
          <TextField
            className="immunotherapyAnswers__textField"
            source="answers.lastImmunotherapy"
            label="immunotherapy.patient.lastImmunotherapy"
            emptyText="-"
          />
          <TextField
            className="immunotherapyAnswers__textField"
            source="answers.lastImmunotherapyDuration"
            label="immunotherapy.patient.lastImmunotherapyDuration"
            emptyText="-"
          />
          <FileField
            target="_blank"
            className="text--purple"
            source="labReport[0]"
            title={translate('testResults.openLabReport')}
            label="immunotherapy.patient.labReport"
          />
        </SimpleShowLayout>
      </div>
    </>
  );
};

export default ImmunotherapyAnswers;
