import React from 'react';
import { useRecordContext } from 'react-admin';

import { PartnerNames } from '../../../constants/partnerNames';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PartnerField = ({ source, label }: { source: any; label?: string }) => {
  const record = useRecordContext(source);
  return (
    <span>
      {record && PartnerNames[record[source] as keyof typeof PartnerNames]}
    </span>
  );
};

export default PartnerField;
