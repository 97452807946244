import React, { ChangeEvent } from 'react';
import Select from '@material-ui/core/Select';

interface Option {
  value: string;
  label: string;
}

interface CustomSelectProps {
  options: Option[];
  value: string | undefined;
  onChange?: (event: ChangeEvent<HTMLSelectElement>) => void;
  emptyLabel?: string;
  disabled?: boolean;
  error?: boolean;
}

const CustomSelect: React.FC<CustomSelectProps> = ({
  options,
  value,
  onChange,
  emptyLabel,
  disabled,
  error,
}) => {
  const handleOnChange = (
    event: ChangeEvent<{ name?: string | undefined; value: unknown }>,
  ) => {
    if (onChange) {
      onChange(event as ChangeEvent<HTMLSelectElement>);
    }
  };

  return (
    <Select native value={value} onChange={handleOnChange} disabled={disabled} error={error}>
      <option value="">{emptyLabel}</option>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </Select>
  );
};

export default CustomSelect;
