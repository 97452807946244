import React, { useEffect, useRef, useState } from 'react';
import { Divider, Button, CircularProgress } from '@material-ui/core';
import {
  useTranslate,
  Record,
  useRedirect,
  SimpleShowLayout,
  useNotify,
} from 'react-admin';
import Allergens, { Allergen } from './Allergens';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { updateResource, uploadFile } from '../../utils/requests';

import { initialAllergens } from './Allergens/data';
import DoctorNotes from './DoctorNotes';
// import SymptomsTreatment from './SymptomsTreatment';
import {
  Medication,
  // SymptomMedication
} from './Medication/types';
import ImmunotherapyTreatment from './ImmunotherapyTreatment';

interface ImmunotherapyFormProps {
  record: Record | undefined;
  readonly?: boolean;
}

const UPLOAD_URL = '/tests/immunotherapy/upload-prescription';

const ImmunotherapyForm: React.FC<ImmunotherapyFormProps> = ({
  record,
  readonly,
}) => {
  const notify = useNotify();
  const translate = useTranslate();
  const redirect = useRedirect();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [defaultAllergens, setDefaultAllergens] =
    useState<Allergen[]>(initialAllergens);
  const [additionalAllergens, setAdditionalAllergens] = useState<Allergen[]>(
    [],
  );

  const [patientEligible, setPatientEligible] = useState<string>();
  const [selectedTherapyType, setSelectedTherapyType] = useState<string>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [scitPossible, setScitPossible] = useState<boolean>();
  const [slitPossible, setSlitPossible] = useState<boolean>();
  const [medications, setMedications] = useState<Medication[]>([
    { medicine: null, startMonths: [] },
  ]);
  const [preparatoryCallRequired, setPreparatoryCallRequired] =
    useState<string>('true');
  const [notes, setNotes] = useState<string>();

  // const symptomFileInputRef = useRef<HTMLInputElement>(null);
  // const [symptomsTreatable, setSymptomsTreatable] = useState<string>();
  // const [symptomMedications, setSymptomMedications] = useState<
  //   SymptomMedication[]
  // >([{ medicine: null, consumptionType: '' }]);

  const [formErrors, setFormErrors] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getSeletectedTherapyType = (
    isScitPossible: boolean,
    isSlitPossible: boolean,
  ): string => {
    if (isScitPossible && isSlitPossible) {
      return 'SCIT & SLIT';
    }
    if (isScitPossible) {
      return 'SCIT';
    }

    return '';
  };

  useEffect(() => {
    if (readonly && record && record.immunotherapy) {
      setDefaultAllergens(record.immunotherapy.allergens);
      setAdditionalAllergens(record.immunotherapy.additionalAllergens);
      setPatientEligible(
        record.immunotherapy.patientEligible ? 'true' : 'false',
      );
      setScitPossible(record.immunotherapy.scitPossible);
      setSlitPossible(record.immunotherapy.slitPossible);
      setSelectedTherapyType(
        getSeletectedTherapyType(
          record.immunotherapy.scitPossible,
          record.immunotherapy.slitPossible,
        ),
      );
      setMedications(record.immunotherapy.medications);
      setNotes(record.immunotherapy.notes);
      // setSymptomsTreatable(
      //   record.immunotherapy.symptomsTreatable ? 'true' : 'false',
      // );
      // setSymptomMedications(record.immunotherapy.symptomMedications);
      setPreparatoryCallRequired(record.immunotherapy.preparatoryCallRequired);
    }
  }, [record]);

  const uploadFileAndUpdateData = async (
    fileRef: React.RefObject<HTMLInputElement>,
    uploadUrl: string = UPLOAD_URL,
  ) => {
    let uploadedFileData = null;
    if (fileRef.current?.files && fileRef.current?.files.length > 0) {
      const selectedFile = fileRef.current.files[0];
      try {
        uploadedFileData = await uploadFile(uploadUrl, selectedFile);
      } catch (fileUploadError) {
        notify('tests.uploadFileErrorMessage', { type: 'warning' });
        setIsLoading(false);
        throw fileUploadError;
      }
    }
    return uploadedFileData;
  };

  const validateFormUpdates = (updates: any) => {
    const errors = [];

    for (let i = 0; i < updates.allergens.length; i += 1) {
      if (
        updates.allergens[i].allergyConfirmed &&
        (!updates.allergens[i].allergicReactionStrength ||
          updates.allergens[i].allergicReactionStrength === '')
      ) {
        errors.push(
          `${translate(
            'immunotherapy.errorMessages.missingReactionStrength',
          )} (${updates.allergens[i].allergen})`,
        );
      }
    }

    for (let i = 0; i < updates.additionalAllergens.length; i += 1) {
      if (
        updates.additionalAllergens[i].allergyConfirmed &&
        (!updates.additionalAllergens[i].allergicReactionStrength ||
          updates.additionalAllergens[i].allergicReactionStrength === '')
      ) {
        errors.push(
          `${translate(
            'immunotherapy.errorMessages.missingReactionStrength',
          )} (${updates.additionalAllergens[i].allergen})`,
        );
      }
    }

    if (patientEligible === undefined || patientEligible === null) {
      errors.push(
        translate('immunotherapy.errorMessages.missingTreatmentEligibility'),
      );
    }

    if (updates.patientEligible) {
      // This field is 'global' and not part of the updates, but still needs to be checked
      if (!selectedTherapyType) {
        errors.push(
          translate('immunotherapy.errorMessages.missingTherapyType'),
        );
      }

      if (updates.slitPossible) {
        for (let i = 0; i < updates.medications.length; i += 1) {
          if (!updates.medications[i].medicine) {
            errors.push(
              `${translate(
                'immunotherapy.errorMessages.missingMedicationMedicine',
              )} ${i + 1}`,
            );
          }
          if (updates.medications[i].startMonths.length === 0) {
            errors.push(
              `${translate(
                'immunotherapy.errorMessages.missingMedicationStart',
              )} ${i + 1} (${updates.medications[i]?.medicine?.name})`,
            );
          }
        }
      }
    }

    // if (symptomsTreatable === undefined || symptomsTreatable === null) {
    //   errors.push(
    //     translate('immunotherapy.errorMessages.missingSymptomsTreatable'),
    //   );
    // }

    if (updates.symptomsTreatable) {
      for (let i = 0; i < updates.symptomMedications.length; i += 1) {
        if (!updates.symptomMedications[i].medicine) {
          errors.push(
            `${translate(
              'immunotherapy.errorMessages.missingSymptomsMedication',
            )} ${i + 1}`,
          );
        }
      }

      for (let i = 0; i < updates.symptomMedications.length; i += 1) {
        if (!updates.symptomMedications[i].consumptionType) {
          errors.push(
            `${translate(
              'immunotherapy.errorMessages.missingSymptomsConsumption',
            )} ${i + 1} (${updates.symptomMedications[i]?.medicine?.name})`,
          );
        }
      }
    }

    return errors;
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const uploadedFileData = await uploadFileAndUpdateData(fileInputRef);
      // TODO: uncomment this when we work on treatment plan for symptoms
      // const symptomUploadFileData = await uploadFileAndUpdateData(
      //   symptomFileInputRef,
      // );

      const cleanMedicationData = medications.filter(
        (medicine) => medicine.medicine !== null,
      );
      // TODO: uncomment this when we work on treatment plan for symptoms
      // const cleanSymptomMedications = symptomMedications.filter(
      //   (medicine) => medicine.medicine !== null,
      // );

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const updates = {
        allergens: defaultAllergens,
        additionalAllergens,
        patientEligible: patientEligible === 'true',
        scitPossible,
        slitPossible,
        medications:
          cleanMedicationData && cleanMedicationData.length > 0
            ? cleanMedicationData
            : null,
        notes,
        prescription: uploadedFileData?.object,

        // TODO: uncomment this when we work on treatment plan for symptoms
        // symptomsTreatable: symptomsTreatable === 'true',
        // symptomMedications:
        //   cleanSymptomMedications && cleanSymptomMedications.length > 0
        //     ? cleanSymptomMedications
        //     : null,
        // symptomPrescription: symptomUploadFileData?.object,
        preparatoryCallRequired: preparatoryCallRequired === 'true',
      };

      const errors = validateFormUpdates(updates);
      setFormErrors(errors);

      if (errors.length === 0) {
        await updateResource(`tests/immunotherapy/${record?.id}`, updates);
        redirect('/tests/immunotherapy');
        notify('tests.addReportSuccessMessage', { type: 'success' });
      }
    } catch (e) {
      notify('tests.addReportErrorMessage', { type: 'warning' });
    }
    setIsLoading(false);
  };

  return (
    <SimpleShowLayout>
      <Allergens
        defaultAllergens={defaultAllergens}
        setDefaultAllergens={setDefaultAllergens}
        additionalAllergens={additionalAllergens}
        setAdditionalAllergens={setAdditionalAllergens}
        readonly={readonly}
      />
      <Divider className="divider" />
      <ImmunotherapyTreatment
        patientEligible={patientEligible}
        setPatientEligible={setPatientEligible}
        selectedTherapyType={selectedTherapyType}
        setSelectedTherapyType={setSelectedTherapyType}
        // scitPossible={scitPossible}
        slitPossible={slitPossible}
        setScitPossible={setScitPossible}
        setSlitPossible={setSlitPossible}
        medications={medications}
        setMedications={setMedications}
        fileInputRef={fileInputRef}
        readonly={readonly}
        setPreparatoryCallRequired={setPreparatoryCallRequired}
        preparatoryCallRequired={preparatoryCallRequired}
      />

      {/* 
       // TODO: uncomment this when we work on treatment plan for symptoms
      <Divider className="divider" />
      <SymptomsTreatment
        symptomsTreatable={symptomsTreatable}
        setSymptomsTreatable={setSymptomsTreatable}
        medications={symptomMedications}
        setMedications={setSymptomMedications}
        fileInputRef={symptomFileInputRef}
        readonly={readonly}
      /> */}
      <Divider className="divider" />
      <DoctorNotes notes={notes} setNotes={setNotes} readonly={readonly} />
      <div className="formErrors">
        <ul>
          {formErrors.map((error) => (
            <li key={`error-${Math.random()}`} className="error">
              * {error}
            </li>
          ))}
        </ul>
      </div>
      {!readonly && (
        <div className="formWrapper">
          <div className="treatmentBlock">
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              {isLoading ? (
                <CircularProgress size="24.5px" />
              ) : (
                translate('immunotherapy.treatment.submit')
              )}
            </Button>
          </div>
        </div>
      )}
    </SimpleShowLayout>
  );
};

export default ImmunotherapyForm;
