import React from 'react';
import {
  FunctionField,
  SimpleShowLayout,
  TextField,
  Record,
  useTranslate,
} from 'react-admin';
import SectionTitle from '../SectionTitle';

import './styles.scss';

const calculateAge = (dateOfBirth: string) => {
  const date = new Date(dateOfBirth);
  const diff = Date.now() - date.getTime();
  const ageDate = new Date(diff);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

const formatDate = (date: string) => {
  const inputDate = new Date(date);
  const day = inputDate.getDate().toString().padStart(2, '0');
  const month = (inputDate.getMonth() + 1).toString().padStart(2, '0');
  const year = inputDate.getFullYear().toString();
  return `${day}.${month}.${year}`;
};

const LANGUAGE_MAP: { [key: string]: string } = {
  en: 'English',
  de: 'German',
  fr: 'French',
  it: 'Italian',
};

interface UserDataBlockProps {
  record: Record | undefined;
}

// User data block used in Immunotherapy & Reviewed Immunotherapy Show view
const UserDataBlock: React.FC<UserDataBlockProps> = ({ record }) => {
  const translate = useTranslate();

  return (
    <SimpleShowLayout>
      <SectionTitle title={translate('immunotherapy.patientInfo')} />
      <FunctionField
        className="userDataBlock__item"
        render={() => translate(`gender.${record?.user.gender}`)}
        label="immunotherapy.patient.gender"
      />
      <FunctionField
        className="userDataBlock__item"
        render={() => formatDate(record?.user.dateOfBirth)}
        label="immunotherapy.patient.dateOfBirth"
        emptyText="-"
      />
      <FunctionField
        className="userDataBlock__item"
        render={() => calculateAge(record?.user.dateOfBirth)}
        label="immunotherapy.patient.age"
        emptyText="-"
      />
      <FunctionField
        className="userDataBlock__item"
        render={() => LANGUAGE_MAP[record?.user.language]}
        label="immunotherapy.patient.language"
      />
      <TextField
        className="userDataBlock__item"
        source="user.phoneNumber"
        label="immunotherapy.patient.phone"
        emptyText="-"
      />
    </SimpleShowLayout>
  );
};

export default UserDataBlock;
