import polyglotI18nProvider from 'ra-i18n-polyglot';
import enTranslationMessages from '../../translations/en.json';
import deTranslationMessages from '../../translations/de.json';
import frTranslationMessages from '../../translations/fr.json';

const DEFAULT_LOCALE = 'en';
const LANGUAGES = ['en', 'de', 'fr'] as const;
const LOCALE_MESSAGES_MAP: { [key in typeof LANGUAGES[number]]: Promise<unknown> | any; } = {
  en: enTranslationMessages,
  de: deTranslationMessages,
  fr: frTranslationMessages
};

const i18nProvider = polyglotI18nProvider(
  (locale) => LOCALE_MESSAGES_MAP[(locale as "de" | "en" | "fr" || DEFAULT_LOCALE)],
  DEFAULT_LOCALE,
);

export default i18nProvider;
