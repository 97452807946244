import * as React from 'react';
import { CreateButton, TopToolbar } from 'react-admin';

import './styles.scss';

const ListActions = () => (
  <TopToolbar className="listActions">
    <CreateButton
      label="ra.action.assign_new_test"
      size="medium"
      variant="contained"
      color="secondary"
    />
  </TopToolbar>
);

export default ListActions;
