import React from 'react';
import { useTranslate } from 'react-admin';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';
import messages from '../BulkTestForm/messages';

const DraftDataTable = ({ record }: { record: any }) => {
  const translate = useTranslate();
  const data = record || {};
  const a = new Date(data?.estimatedSamplingTime || new Date());
  a.setHours(a.getHours() + 1);
  const [date, time] = a.toISOString()?.split('T') || [];
  return (
    <Paper>
      <Table aria-label="caption table">
        <TableHead>
          <TableRow>
            <TableCell>{translate(messages.partners)}</TableCell>
            <TableCell>{translate(messages.fileDefinition)}</TableCell>
            <TableCell>{translate(messages.contact)}</TableCell>
            <TableCell>{translate(messages.routing)}</TableCell>
            <TableCell>{translate(messages.samplingMethod)}</TableCell>
            <TableCell>{translate(messages.estimatedSamplingTime)}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key={data.sampling}>
            <TableCell>{data.partnerName || '-'}</TableCell>
            <TableCell>{data.importDefinitionName || '-'}</TableCell>
            <TableCell>{data.contact || '-'}</TableCell>
            <TableCell>
              {`${data?.routing?.lab || ''}/${data?.routing?.sender || ''}` ||
                '-'}
            </TableCell>
            <TableCell>{data.sampling || '-'}</TableCell>
            <TableCell>
              {`${date?.replaceAll('-', '.')} ${time?.slice(0, 5)} (CET)` ||
                '-'}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  );
};

export default DraftDataTable;
