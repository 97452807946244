import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

const SubmitApproveDialog = ({
  open,
  title,
  handleConfirm,
  handleCancel,
}: {
  title: string;
  open: boolean;
  handleConfirm: (confirm?: any) => void;
  handleCancel: () => void;
}) => (
  <Dialog
    open={open}
    onClose={handleCancel}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
    <DialogActions>
      <Button onClick={handleConfirm} autoFocus>
        OK
      </Button>
      <Button onClick={handleCancel}>Cancel</Button>
    </DialogActions>
  </Dialog>
);
export default SubmitApproveDialog;
