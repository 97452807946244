import { makeStyles } from '@material-ui/core';
import { EditableDatagrid } from '@react-admin/ra-editable-datagrid';
import React, { useMemo } from 'react';
import { FunctionField, TextField } from 'react-admin';
import { useForm } from 'react-final-form';
import { useSelector } from 'react-redux';
import { ErrorsCount } from '../../containers/MassTests/DraftEditPage/ErrorsCount';
import { LengthField } from '../../containers/MassTests/DraftEditPage/LengthField';
import GridFields from '../../models/gridFields';
import RowFormData from '../../models/rowFormData';
import getFieldByType from '../../utils/helpers/getFieldByType';
import ActionsColumn from '../ActionColumn';
import TestedPersonsRowForm from '../TestedPersonsRowForm';

const defaultRows: RowFormData[] = [
  {
    id: 'string',
    dataType: 'string',
    database: 'string',
    friendlyName: 'string',
    canBeEditWithSyncState: true,
    required: true,
  },
  {
    id: 'string',
    dataType: 'number',
    database: 'number',
    friendlyName: 'number',
    canBeEditWithSyncState: true,
    required: false,
  },
];

const useStyles = makeStyles({
  table: {},
});
const postRowStyle = (record: any) => {
  let backgroundColor = 'white';
  const pointerEvents = 'all';
  if (record?.errors?.length > 0) {
    backgroundColor = 'rgb(255, 92, 92)';
  }
  if (record.state === 'synced') {
    backgroundColor = '#efe';
  }
  return {
    backgroundColor,
    pointerEvents,
  };
};
const TestedPersonsGrid = ({
  table,
  idDraft,
  onError,
  onClear,
  module,
  ...props
}: {
  table?: GridFields;
  idDraft: string;
  onError: () => void;
  onClear: () => void;
  module: string;
}) => {
  const form = useForm();
  const persons = useMemo(() => form.getState().values?.testedPersons, [form]);
  if (
    persons?.some((err: any) => err.errors?.length && err.errors?.length > 0)
  ) {
    onError();
  } else {
    onClear();
  }
  const open = useSelector((state: any) => state.admin.ui.sidebarOpen);
  const path = `${module}/${idDraft}`;
  const classes = useStyles();
  return (
    <>
      <LengthField />
      <ErrorsCount />
      <div
        className={`grid-wrapper${open ? '' : ' grid-wrapper-max'} ${
          classes.table
        } table-${(props as any).target}`}
      >
        <EditableDatagrid
          className={classes.table}
          undoable
          rowStyle={postRowStyle}
          actions={<ActionsColumn path={path} redirect={false} {...props} />}
          createForm={
            <TestedPersonsRowForm
              firstId={idDraft}
              rows={table || defaultRows}
            />
          }
          editForm={
            <TestedPersonsRowForm
              firstId={idDraft}
              rows={table || defaultRows}
            />
          }
          rowClick="edit"
          {...props}
        >
          {[...(table || defaultRows)].map((input) =>
            getFieldByType(input.dataType, {
              label: input.friendlyName,
              source: input.database,
            }),
          )}
          <TextField source="state" {...props} />
          <FunctionField
            label="Errors"
            render={(record: any) =>
              typeof record.errors === 'string' || !record.errors ? (
                record.errors
              ) : (
                <ul>
                  {record.errors.map((err: string | object) => (
                    <li>
                      {typeof err === 'string' ? err : Object.values(err)[0]}
                    </li>
                  ))}
                </ul>
              )
            }
          />
        </EditableDatagrid>
      </div>
    </>
  );
};

export default TestedPersonsGrid;
