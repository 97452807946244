export interface IAvailableTest {
  name: string;
  description?: string;
  id: string;
  availableForPartner: string[];
}

export const availableTests: IAvailableTest[] = [
  {
    name: 'Fatigue Check',
    description: 'Fatigue Check',
    id: 'fatigueV3',
    availableForPartner: []
  },
  {
    name: 'Advanced Iron',
    description: 'Advanced Iron (Sante24)',
    id: 'advancedIron',
    availableForPartner: []
  },
  {
    name: 'STD Premium',
    description: 'STD Premium',
    id: 'stdPremium',
    availableForPartner: []
  },
  {
    name: 'STD Premium - Easy Sampling',
    description: 'STD Premium - Easy Sampling',
    id: 'stdPremiumV2',
    availableForPartner: []
  },
  {
    name: 'Vitamin B12 tracking',
    description: 'Vitamin B12 tracking',
    id: 'b12',
    availableForPartner: []
  },
  {
    name: '360° Allergy Check',
    description: '360° Allergy Check',
    id: 'allergyCheckForImmunotherapy',
    availableForPartner: ['s24']
  },
  {
    name: "HPV Check",
    description: "HPV Check",
    id: "hpvCheck",
    availableForPartner: []
  }
];
