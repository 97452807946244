import * as React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Link, Title, useTranslate } from 'react-admin';
import { Box } from '@material-ui/core';
import { WithPermissions } from '@react-admin/ra-rbac';
import { MASS_TESTS_MODULE } from '../../constants/apiUrls';
import messages from './messages';

const Dashboard = () => {
  const translate = useTranslate();

  return (
    <Card style={{ padding: '30px' }}>
      <Title title="Dashboard" />
      <CardContent>{translate(messages.dashboardMessage)}</CardContent>
      <WithPermissions action="create" resource={MASS_TESTS_MODULE}>
        <Link
          style={{
            marginLeft: '10px',
            border: '1px solid #3f51b5',
            display: 'inline-block',
            borderRadius: '5px',
          }}
          to={`/${MASS_TESTS_MODULE}/create`}
          color="primary"
        >
          <Box p={1}>New upload</Box>
        </Link>
      </WithPermissions>
    </Card>
  );
};

export default Dashboard;
