import React from 'react';
import { Typography } from '@material-ui/core';

const FR = () => (
  <>
    <Typography variant="body2">
      <br />
      <br />
      Salut !
      <br />
      <br />
      On t&apos;a assigné un test de laboratoire à faire chez toi par{' '}
      <b>santé24</b>.
      <br />
      <br />
      Voici comment procéder :
      <br />
      <br />
      <b>1. Commander le test</b>
      <br />
      Clique sur le bouton &quot;Commander &quot; en bas de cet email et suis
      les instructions pour finaliser le processus de commande. Aucun paiement
      n&apos;est requis, car nous facturons directement ton assurance.
      <br />
      <br />
      <b>2. Effectuer facilement ton test à domicile</b>
      <br />
      1 à 2 jours ouvrables après la commande, tu recevras ton kit de test livré
      chez toi dans une enveloppe discrète par courrier. Le kit de test
      contiendra un code d&apos;activation ainsi que tous les matériaux et des
      instructions faciles à suivre pour prélever ton échantillon. Ensuite,
      place ton test dans la boîte de retour fournie et dépose-la dans
      n&apos;importe quelle boîte aux lettres de La Poste suisse.
      <br />
      <br />
      <b>3. Recevoir tes résultats</b>
      <br />
      Ton échantillon sera analysé par Medisyn, un prestataire de laboratoire
      suisse de premier plan. Une fois que tes résultats seront prêts, tu
      recevras un appel de santé24 pour les discuter. Après cet appel, tes
      résultats seront disponibles en ligne.
    </Typography>
    <button type="button" className="sidebar__button">
      Ordina
    </button>
    <Typography variant="body2">
      Si tu as des questions, tu peux nous contacter à tout moment à
      l&apos;adresse service@yourself.health!
      <br />
      <br />
      Bien amicalement,
      <br />
      <br />
      L&apos;équipe Health Yourself
      <br />
      <br />
      Lerchenfeldstrasse 3 <br />
      9014 St. Gallen
    </Typography>
  </>
);

export default FR;
