import React, { useMemo, useState } from 'react';
import {
  SimpleForm,
  CheckboxGroupInput,
  SaveContextProvider,
  TextInput,
  TextField,
} from 'react-admin';
import PrescriptionEditToolbar from '../../../components/PrescriptionEditToolbar';
import { PRESCRIPTIONS_MODULE } from '../../../constants/apiUrls';
import useHandlePrescription from '../../../hooks/useHandlePrescription';
import messages from '../messages';

const VideoReviewForm = ({
  id,
  status,
  rejectionReason,
  hasReasonForCallback,
}: {
  id: string;
  status?: string;
  rejectionReason?: string;
  hasReasonForCallback?: boolean;
}) => {
  const [isReject, setIsReject] = useState<boolean>(false);
  const handleSave = useHandlePrescription(
    'create',
    `${PRESCRIPTIONS_MODULE}/${id}`,
  );

  const choices = [
    { id: 'identityVerified', name: messages.identityVerified },
    { id: 'noHealthRisks', name: messages.noHealthRisks },
    { id: 'previousUse', name: messages.previousUse },
  ];
  if (hasReasonForCallback) {
    choices.push({ id: 'callbackCompleted', name: messages.callbackCompleted });
  }

  const [saving] = useState<boolean>();
  const saveContext = useMemo(
    () => ({
      save: handleSave,
      setOnFailure: () => {
        // console.error('Error saving');
      },
      saving,
    }),
    [saving, handleSave],
  );
  return (
    <SaveContextProvider value={saveContext}>
      <SimpleForm
        save={(val: any) => {
          handleSave(
            val?.reviewCriteria?.length === (hasReasonForCallback ? 4 : 3) &&
              !isReject
              ? {
                  id: val.id,
                  reviewCriteria: Object.fromEntries(
                    val.reviewCriteria.map((x: string) => [x, true]),
                  ),
                }
              : { id: val.id, rejectionReason: val.rejectionReason },
          );
        }}
        validate={(data: any) => {
          if (
            !isReject &&
            data.reviewCriteria &&
            data.reviewCriteria.length !== (hasReasonForCallback ? 4 : 3)
          ) {
            return 'Could submit only after all checks';
          }
          return null;
        }}
        toolbar={
          <PrescriptionEditToolbar
            isReject={isReject}
            statusCompleted={status === 'complete'}
            onReject={() => {
              setIsReject(true);
            }}
          />
        }
      >
        <CheckboxGroupInput
          source="reviewCriteria"
          label={messages.reviewCriteria}
          disabled={isReject || status === 'complete'}
          choices={choices}
        />
        {rejectionReason && (
          <TextField
            source="rejectionReason"
            label={messages.rejectionReason}
          />
        )}
        {isReject && (
          <TextInput
            multiline
            source="rejectionReason"
            label={messages.rejectionReason}
          />
        )}
      </SimpleForm>
    </SaveContextProvider>
  );
};
export default VideoReviewForm;
