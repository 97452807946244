import axios, { AxiosRequestConfig, AxiosRequestHeaders, Method } from 'axios';
import { BASE_ADMIN_URL } from '../constants/apiUrls';
import {
  IDENTITY_KEY,
  REFRESH_KEY,
  TOKEN_KEY,
} from '../constants/localStorage';
import FormChoice from '../models/formChoice';
import BulkTestFormChoices from '../models/formOptionLists';
import GridFields from '../models/gridFields';

const getDefaultHeaders = () => {
  const defaultHeaders: AxiosRequestHeaders = {};
  const token = localStorage.getItem(TOKEN_KEY);
  if (token) {
    defaultHeaders.Authorization = token;
  }
  return defaultHeaders;
};

const getAxiosInstance = () =>
  axios.create({
    baseURL: BASE_ADMIN_URL,
    headers: {
      ...getDefaultHeaders(),
    },
  });

export const normalizeUrl = (url: string) => `${url}`.replace('//', '/');

export const makeRequest = (
  method: Method,
  resource: string,
  params: AxiosRequestConfig<unknown>,
) => {
  const axiosInstance = getAxiosInstance();
  axiosInstance.interceptors.request.use(
    (config) => {
      const accessToken = localStorage.getItem(TOKEN_KEY);
      if (accessToken) {
        // eslint-disable-next-line no-param-reassign
        (config.headers || {}).Authorization = `Bearer ${accessToken}`;
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    },
  );
  // response interceptor to refresh token on receiving token expired error
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      const originalRequest = error.config;
      const refreshToken = localStorage.getItem(REFRESH_KEY);
      const identity = JSON.parse(localStorage.getItem(IDENTITY_KEY) || '{}');
      if (
        error.response.data.message !== 'Wrong refresh token' &&
        refreshToken &&
        identity.username &&
        error.response.status === 401 &&
        // eslint-disable-next-line no-underscore-dangle
        !originalRequest._retry
      ) {
        // eslint-disable-next-line no-underscore-dangle
        originalRequest._retry = true;
        return axiosInstance
          .post(`${BASE_ADMIN_URL}/auth/refresh-token`, {
            username: identity.username,
            refreshToken,
          })
          .then((res) => {
            if (res.status === 200) {
              localStorage.setItem(TOKEN_KEY, res.data.accessToken);
              return axiosInstance(originalRequest);
            }
            return null;
          })
          .catch(() => {
            localStorage.removeItem(REFRESH_KEY);
          });
      }
      return Promise.reject(error);
    },
  );
  const url = normalizeUrl(resource);
  return axiosInstance.request({
    url,
    method,
    ...params,
  });
};
export const getIsTestNotExists = async (
  endpoint: string,
  estimatedSamplingTime: string,
  partner: string,
): Promise<boolean> => {
  const response = await makeRequest('GET', endpoint, {
    data: {
      estimatedSamplingTime,
      partner,
    },
  });
  return response.status === 200;
};
export const getFormChoices = async (
  endpoint: string,
): Promise<BulkTestFormChoices> => {
  const response = await makeRequest('GET', endpoint, {});
  return response.data;
};

export const getGridFields = async (
  locale: string,
  endpoint: string,
): Promise<{
  fields: GridFields;
  records: Record<string, string>;
}> => {
  const response = await makeRequest('GET', `${endpoint}?lang=${locale}`, {});
  return response.data;
};
export const getSamplingChoicesByLab = async (
  endpoint: string,
  lab: string,
): Promise<FormChoice[]> => {
  const response = await makeRequest('GET', `${endpoint}?lab=${lab}`, {});
  return response.data;
};
export const getChoicesByPartner = async (
  endpoint: string,
  partner: string,
): Promise<FormChoice[]> => {
  const response = await makeRequest(
    'GET',
    `${endpoint}/partners?partner=${partner}`,
    {},
  );
  return response.data;
};

export const getBarcodeFormChoicesForPartners = async (
  endpoint: string,
): Promise<FormChoice[]> => {
  const response = await makeRequest('GET', `${endpoint}`, {});
  return response.data;
};
export const getImportDefinitions = async (
  endpoint: string,
  partner: string,
): Promise<FormChoice[]> => {
  const response = await makeRequest(
    'GET',
    `${endpoint}?partnerId=${partner}`,
    {},
  );
  return response.data;
};
export const getEstimatedSamplingTime = async (
  endpoint: string,
  partner: string,
  importDefinition: string,
): Promise<FormChoice[]> => {
  const response = await makeRequest(
    'GET',
    `${endpoint}?partnerId=${partner}&importDefinition=${importDefinition}`,
    {},
  );
  return response.data;
};
export const getFormChoicesForSchool = async (
  endpoint: string,
  partner: string,
  importDefinition: string,
  estimatedSamplingTime: string,
): Promise<FormChoice[]> => {
  const response = await makeRequest(
    'GET',
    `${endpoint}?partnerId=${partner}&importDefinition=${importDefinition}&estimatedSamplingTime=${estimatedSamplingTime}`,
    {},
  );
  return response.data;
};
export const getFormChoicesForClasses = async (
  endpoint: string,
  partner: string,
  importDefinition: string,
  estimatedSamplingTime: string,
  school: string,
): Promise<FormChoice[]> => {
  const response = await makeRequest(
    'GET',
    `${endpoint}?partnerId=${partner}&importDefinition=${importDefinition}&estimatedSamplingTime=${estimatedSamplingTime}&schoolId=${school}`,
    {},
  );
  return response.data;
};
export const getTestedPersons = async (
  endpoint: string,
  partner: string,
  estimatedSamplingTime: string,
  school: string,
  className?: string,
): Promise<Record<string, string>[]> => {
  const response = await makeRequest(
    'GET',
    `${endpoint}?partnerId=${partner}&estimatedSamplingTime=${estimatedSamplingTime}&schoolId=${school}${
      className ? `&classId=${className}` : ''
    }`,
    {},
  );
  return response.data;
};
export const getFormChoicesForTime = async (
  endpoint: string,
  partner: string,
): Promise<{ estimatedSamplingTime: FormChoice[] }> => {
  const response = await makeRequest(
    'GET',
    `${endpoint}?partnerId=${partner}`,
    {},
  );
  return response.data;
};
// Гет с query partnerId на даты.
// гет с partnerId и estimatedSamplingTime(из селекта) на получение школы / класс в одном селекте
// гет с partnerId, estimatedSamplingTime, schoolAndClass получает id драфта.

export const updateResource = async (
  endpoint: string,
  data: any, // Has to be any because [key: string]: string does not cover for nested structures
) => {
  const response = await makeRequest('PUT', endpoint, {
    data,
  });
  return response.data;
}

export const uploadFile = async (
  endpoint: string,
  file: File,
  additionalData: Record<string, string> = {},
) => {
  const formData = new FormData();
  formData.append('file', file);

  Object.keys(additionalData).forEach((key) => {
    formData.append(key, additionalData[key]);
  });

  const response = await makeRequest('POST', endpoint, {
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return response.data;
}
