const antiHCV = {
  parameters: [
    {
      name: 'antiHCV',
      id: 'antiHCV',
      displayName: 'Hepatitis C - Anti-HCV',
      labReportName: 'Hepatitis C - Anti-HCV',
      validFrom: '2022/01/01 0:00',
      validThrough: null,
      stringValuesSupported: [
        'positive',
        'negative',
        'fault',
      ],
      outcomes: [{
        color: '#1f7359',
        description: 'antiHCV-negative',
        name: 'negative',
        displayInterpretation: 'Negative'
      },
      {
        color: '#f52d0a',
        description: 'antiHCV-positive',
        name: 'positive',
        displayInterpretation: 'Positive',
      },
      {
        color: '#000000',
        description: 'antiHCV-fault',
        name: 'fault',
        displayInterpretation: 'Fault',
      }],
    },
  ],
};

export default antiHCV;