import React from 'react';
import { useTranslate } from 'react-admin';
import { Typography, Card, CardContent } from '@material-ui/core';

import IFormValues from '../types';
import EN from './EmailExample/EN';
import DE from './EmailExample/DE';
import FR from './EmailExample/FR';
import IT from './EmailExample/IT';

import './styles.scss';

const getBody = (language: string) => {
  switch (language) {
    case 'fr':
      return <FR />;
    case 'en':
      return <EN />;
    case 'de':
      return <DE />;
    case 'it':
      return <IT />;
    default:
      return <EN />;
  }
};

const getSubject = (language: string) => {
  switch (language) {
    case 'fr':
      return 'Commande le test de laboratoire assigné par sante24';
    case 'en':
      return 'Order your assigned santé24 lab test';
    case 'de':
      return 'Von santé24 zugewiesenen Labortest bestellen';
    case 'it':
      return 'Ordina il test di laboratorio assegnato da sante24';
    default:
      return 'Order your assigned santé24 lab test';
  }
};

const Sidebar = ({ formValues }: { formValues: IFormValues }) => {
  const translate = useTranslate();
  const { testType, language } = formValues;
  const infoConfirmed = testType && language;

  const EmailExample = getBody(language);
  const title = getSubject(language);
  return (
    <Card className="sidebar">
      <CardContent>
        <Typography variant="h6">
          {infoConfirmed ? title : translate('tests.emailPreview')}
        </Typography>
        {testType && language ? (
          <div className="sidebar__body">{EmailExample}</div>
        ) : (
          <Typography variant="body2">
            {translate('tests.emailPreviewText')}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default Sidebar;
