const alt = {
  parameters: [
    {
      name: 'ALT-female',
      displayName: 'ALT',
      id: 'alt',
      validFrom: '2022/03/07 1:00',
      validThrough: null,
      interpretationsSupported: [
        'normal',
        'too-high',
        'fault',
      ],
      criteria: {
        sexe: [
          'female',
        ],
        age: {
          min: 5,
          max: 110,
        },
      },
      ranges: [{
        color: '#1f7359',
        description: 'ALT-female-normal',
        lowerLimit: 0,
        lowerLimitOperator: '>=',
        upperLimit: 35,
        upperLimitOperator: '<',
        unit: 'U/l',
        interpretation: 'normal',
        displayInterpretation: 'Normal',
      },
      {
        color: '#f52d0a',
        description: 'ALT-female-too-high',
        lowerLimit: 35,
        lowerLimitOperator: '>=',
        upperLimit: 200,
        upperLimitOperator: '',
        unit: 'U/l',
        interpretation: 'too-high',
        displayInterpretation: 'Too high',
      }],
    },
    {
      name: 'ALT-male',
      displayName: 'ALT',
      id: 'alt',
      validFrom: '2022/03/07 1:00',
      validThrough: null,
      interpretationsSupported: [
        'normal',
        'too-high',
        'fault',
      ],
      criteria: {
        sexe: [
          'male',
        ],
        age: {
          min: 5,
          max: 110,
        },
      },
      ranges: [{
        color: '#1f7359',
        description: 'ALT-male-normal',
        lowerLimit: 0,
        lowerLimitOperator: '>=',
        upperLimit: 50,
        upperLimitOperator: '<',
        unit: 'U/l',
        interpretation: 'normal',
        displayInterpretation: 'Normal',
      },
      {
        color: '#f52d0a',
        description: 'ALT-male-too-high',
        lowerLimit: 50,
        lowerLimitOperator: '>=',
        upperLimit: 200,
        upperLimitOperator: '',
        unit: 'U/l',
        interpretation: 'too-high',
        displayInterpretation: 'Too high',
      }],
    },
  ],
};

export default alt;