import React from 'react';
import { useTranslate } from 'react-admin';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'end',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    input: {
      display: 'none',
    },
  }),
);

const UploadButtons = ({
  inputRef,
}: {
  inputRef: React.RefObject<HTMLInputElement>;
}) => {
  const [selectedFileName, setSelectedFileName] = React.useState<string | null>(
    null,
  );
  const translate = useTranslate();
  const classes = useStyles();

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files && files.length > 0) {
      const file = files[0];
      setSelectedFileName(file.name);
    }
  };
  return (
    <div className={classes.root}>
      <input
        accept="image/jpeg, image/gif, image/png, application/pdf"
        className={classes.input}
        multiple
        onChange={handleOnChange}
        type="file"
        ref={inputRef}
      />
      <Button
        variant="contained"
        color="primary"
        component="span"
        onClick={() => inputRef.current?.click()}
      >
        {translate('immunotherapy.treatment.chooseFile')}
      </Button>
      {selectedFileName && <div>{selectedFileName}</div>}
    </div>
  );
};

export default UploadButtons;
