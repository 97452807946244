const vitaminD3 = {
  parameters: [
    {
      name: 'vitamin-d3',
      id: 'vitamin-d3',
      displayName: '25-OH-Vitamin D',
      validFrom: '2021/01/05 1:00',
      validThrough: null,
      interpretationsSupported: [
        'severe',
        'deficiency',
        'borderline',
        'low',
        'optimum',
        'normal',
        'high',
        'excessive',
        'toxic',
        'fault',
      ],
      ranges: [{
        color: '#f52d0a',
        description: 'vitamin-D3-severe',
        lowerLimit: 0,
        lowerLimitOperator: '>=',
        upperLimit: 30,
        upperLimitOperator: '<',
        unit: 'nmol/l',
        interpretation: 'severe',
        displayInterpretation: 'Severe',
      },
      {
        color: '#ff9400',
        description: 'vitamin-D3-deficiency',
        lowerLimit: 30,
        lowerLimitOperator: '>=',
        upperLimit: 50,
        upperLimitOperator: '<=',
        unit: 'nmol/l',
        interpretation: 'deficiency',
        displayInterpretation: 'Deficiency',
      },
      {
        color: '#fdad02',
        description: 'vitamin-D3-borderline',
        lowerLimit: 50,
        lowerLimitOperator: '>',
        upperLimit: 75,
        upperLimitOperator: '<=',
        unit: 'nmol/l',
        interpretation: 'borderline',
        displayInterpretation: 'Borderline',
      },
      {
        color: '#fdad02',
        description: 'vitamin-D3-low',
        lowerLimit: 75,
        lowerLimitOperator: '>',
        upperLimit: 100,
        upperLimitOperator: '<=',
        unit: 'nmol/l',
        interpretation: 'low',
        displayInterpretation: 'Low',
        treatAs: 'normal',
      },
      {
        color: '#1f7359',
        description: 'vitamin-D3-optimum',
        lowerLimit: 100,
        lowerLimitOperator: '>',
        upperLimit: 125,
        upperLimitOperator: '<=',
        unit: 'nmol/l',
        interpretation: 'optimum',
        displayInterpretation: 'Optimum',
        treatAs: 'normal',
      },
      {
        color: '#1f7359',
        description: 'vitamin-D3-normal',
        lowerLimit: 125,
        lowerLimitOperator: '>',
        upperLimit: 150,
        upperLimitOperator: '<=',
        unit: 'nmol/l',
        interpretation: 'normal',
        displayInterpretation: 'Normal',
      },
      {
        color: '#fdad02',
        description: 'vitamin-D3-high',
        lowerLimit: 150,
        lowerLimitOperator: '>',
        upperLimit: 250,
        upperLimitOperator: '<=',
        unit: 'nmol/l',
        interpretation: 'high',
        displayInterpretation: 'High',
        treatAs: 'normal',
      },
      {
        color: '#ff9400',
        description: 'vitamin-D3-excessive',
        lowerLimit: 250,
        lowerLimitOperator: '>',
        upperLimit: 375,
        upperLimitOperator: '<=',
        unit: 'nmol/l',
        interpretation: 'excessive',
        displayInterpretation: 'Excessive',
        treatAs: 'normal',
      },
      {
        color: '#f52d0a',
        description: 'vitamin-D3-toxic',
        lowerLimit: 375,
        lowerLimitOperator: '>',
        upperLimit: 450,
        upperLimitOperator: '',
        unit: 'nmol/l',
        interpretation: 'toxic',
        displayInterpretation: 'Toxic',
      }],
    },
  ],
};

export default vitaminD3;