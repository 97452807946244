import React, { useEffect } from 'react';
import { Admin, Login, useTranslate } from 'react-admin';
import { createTheme } from '@material-ui/core/styles';
import './styles.scss';
import { Resource } from '@react-admin/ra-rbac';
import {
  dataProvider,
  i18nProvider,
  authProvider,
} from '../../utils/providers';
import messages from './messages';
import Dashboard from '../../components/Dashboard';
import CustomLayout from '../../components/Layout';
import DraftEditPage from '../MassTests/DraftEditPage';
import {
  PRESCRIPTIONS_MODULE,
  USERS_MODULE,
  TESTS_MODULE,
  TEST_ASSIGNMENT_MODULE,
  REVIEWED_TESTS_MODULE,
  IMMUNOTHERAPY_MODULE,
  REVIEWED_IMMUNOTHERAPY_MODULE,
} from '../../constants/apiUrls';
import PrescriptionList from '../Prescriptions/PrescriptionList';
import PrescriptionShow from '../Prescriptions/PrescriptionShow';
import UsersList from '../Users/UsersList';
import UsersCreate from '../Users/UsersCreate';
import TestsList from '../Tests/TestsList';
import TestsShow from '../Tests/TestsShow';
import ImmunotherapyTestsList from '../Immunotherapy/ImmunotherapyTestsList';
import ImmunotherapyTestsShow from '../Immunotherapy/ImmunotherapyTestsShow';
import ReviewedImmunotherapyTestsList from '../ReviewedImmunotherapy/ReviewedImmunotherapyTestsList';
import ReviewedImmunotherapyTestShow from '../ReviewedImmunotherapy/ReviewedImmunotherapyTestShow';
import TestAssignmentList from '../TestAssignment/TestAssignmentList';
import TestAssignmentCreate from '../TestAssignment/TestAssignmentCreate';

const theme = createTheme({
  palette: {
    text: {
      secondary: 'rgba(102, 102, 102, 1)',
    },
    secondary: {
      main: 'rgba(97, 65, 155, 1)',
    },
    background: { default: '#fff', paper: '#fff' },
  },
});

const App = () => {
  useEffect(() => {
    document.title = 'Health Yourself | Work-with';
  }, []);

  return (
    <Admin
      theme={theme}
      // @ts-ignore
      dataProvider={dataProvider}
      authProvider={authProvider}
      i18nProvider={i18nProvider}
      loginPage={Login}
      layout={CustomLayout}
      dashboard={Dashboard}
    >
      <Resource name="drafts" edit={DraftEditPage} />
      <Resource
        name={USERS_MODULE}
        list={UsersList}
        create={UsersCreate}
        options={{
          label: useTranslate()(messages.usersModule),
        }}
      />
      <Resource
        name={PRESCRIPTIONS_MODULE}
        list={PrescriptionList}
        show={PrescriptionShow}
        options={{
          label: useTranslate()(messages.prescriptionModule),
        }}
      />
      <Resource
        name={TEST_ASSIGNMENT_MODULE}
        list={TestAssignmentList}
        create={TestAssignmentCreate}
        options={{
          label: useTranslate()(messages.testAssignmentModule),
        }}
      />
      <Resource
        name={TESTS_MODULE}
        list={TestsList}
        show={TestsShow}
        options={{
          label: useTranslate()(messages.testsModule),
        }}
      />
      <Resource
        name={REVIEWED_TESTS_MODULE}
        list={TestsList}
        show={TestsShow}
        options={{
          label: useTranslate()(messages.reviewedTestsModule),
        }}
      />
      <Resource
        name={IMMUNOTHERAPY_MODULE}
        list={ImmunotherapyTestsList}
        show={ImmunotherapyTestsShow}
        options={{
          label: useTranslate()(messages.immunotherapyModule),
        }}
      />
      <Resource
        name={REVIEWED_IMMUNOTHERAPY_MODULE}
        list={ReviewedImmunotherapyTestsList}
        show={ReviewedImmunotherapyTestShow}
        options={{
          label: useTranslate()(messages.reviewedImmunotherapyModule),
        }}
      />
    </Admin>
  );
};

export default App;
