const albumin = {
  parameters: [
    {
      name: 'albumin',
      displayName: 'Albumin',
      id: 'albumin',
      validFrom: '2022/05/24 1:00',
      validThrough: null,
      interpretationsSupported: [
        'normal',
        'deficiency',
        'high',
      ],
      ranges: [{
        color: '#f52d0a',
        description: 'albumin-deficiency',
        lowerLimit: 0,
        lowerLimitOperator: '>=',
        upperLimit: 35,
        upperLimitOperator: '<',
        unit: 'g/l',
        interpretation: 'deficiency',
        displayInterpretation: 'Deficiency',
      },
      {
        color: '#1f7359',
        description: 'albumin-normal',
        lowerLimit: 35,
        lowerLimitOperator: '>=',
        upperLimit: 52,
        upperLimitOperator: '<=',
        unit: 'g/l',
        interpretation: 'normal',
        displayInterpretation: 'Normal',
      },
      {
        color: '#fdad02',
        description: 'albumin-high',
        lowerLimit: 52,
        lowerLimitOperator: '>',
        upperLimit: 100,
        upperLimitOperator: '',
        unit: 'g/l',
        interpretation: 'high',
        displayInterpretation: 'High',
      }],
    },
  ],
};

export default albumin;