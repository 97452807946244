import React, { ChangeEvent } from 'react';
import { useTranslate } from 'react-admin';

import CustomTextArea from '../../CustomTextArea';
import SectionTitle from '../../SectionTitle';

import './styles.scss';

interface DoctorNotesProps {
  notes: string | undefined;
  setNotes: React.Dispatch<React.SetStateAction<string | undefined>>;
  readonly?: boolean;
}

const DoctorNotes: React.FC<DoctorNotesProps> = ({
  notes,
  setNotes,
  readonly,
}) => {
  const translate = useTranslate();
  const handleNotesChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setNotes(event.target.value);
  };
  return (
    <div className="doctorNotes">
      <SectionTitle title={translate('immunotherapy.doctorNotes')} />
      <p>{translate('immunotherapy.treatment.diagnosisAndTreatmentNotes')}</p>
      <CustomTextArea
        value={notes}
        onChange={handleNotesChange}
        disabled={readonly}
      />
    </div>
  );
};

export default DoctorNotes;
