const transferrinSaturation = {
  parameters: [
    {
      name: 'transferrinSaturation',
      displayName: 'Transferrin saturation',
      id: 'transferrinSaturation',
      validFrom: '2023/06/14 1:00',
      validThrough: null,
      interpretationsSupported: [
        'too-low',
        'normal',
        'too-high',
        'fault',
      ],
      ranges: [{
        color: '#ff9400',
        description: 'transferrinSaturation-too-low',
        lowerLimit: 0,
        lowerLimitOperator: '>=',
        upperLimit: 16,
        upperLimitOperator: '<',
        unit: '%',
        interpretation: 'low',
        displayInterpretation: 'Low',
      },
      {
        color: '#1f7359',
        description: 'transferrinSaturation-normal',
        lowerLimit: 16,
        lowerLimitOperator: '>=',
        upperLimit: 45,
        upperLimitOperator: '<=',
        unit: '%',
        interpretation: 'normal',
        displayInterpretation: 'Normal',
      },
      {
        color: '#fdad02',
        description: 'transferrinSaturation-too-high',
        lowerLimit: 45,
        lowerLimitOperator: '>',
        upperLimit: 2000,
        upperLimitOperator: '',
        unit: '%',
        interpretation: 'too-high',
        displayInterpretation: 'High',
      }],
    },
  ],
};

export default transferrinSaturation;
