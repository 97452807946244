import React from 'react';
import { Toolbar, SaveButton } from 'react-admin';
import SendIcon from '@material-ui/icons/Send';

const ActionButton = (props: any) => (
  <Toolbar {...props}>
    <SaveButton
      label="ra.action.send"
      redirect="show"
      submitOnEnter
      icon={<SendIcon />}
    />
  </Toolbar>
);

export default ActionButton;
