import { Typography } from '@material-ui/core';
import React from 'react';
import {
  ListProps,
  TextField,
  Datagrid,
  List,
  useTranslate,
} from 'react-admin';
import Paginator from '../../../components/Paginator';
import { TESTS_PER_PAGE } from '../../../constants/pagination';
import messages from '../messages';

const UsersList = (props: ListProps) => {
  const translate = useTranslate();
  return (
    <>
      <Typography variant="h4">{translate(messages.listOfUsers)}</Typography>
      <List
        {...props}
        className="clients-list"
        pagination={<Paginator perPage={TESTS_PER_PAGE} />}
        perPage={TESTS_PER_PAGE}
        bulkActionButtons={false}
        {...props}
      >
        <Datagrid rowClick="show">
          <TextField
            emptyText="-"
            source="username"
            label={messages.username}
          />
          <TextField
            emptyText="-"
            source="partnerCode"
            label={messages.partnerCode}
          />
        </Datagrid>
      </List>
    </>
  );
};

export default UsersList;
