import React, { ChangeEvent } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import './styles.scss';

interface CheckboxProps {
  label: string;
  checked: boolean | undefined;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

const CustomCheckbox: React.FC<CheckboxProps> = ({
  label,
  checked,
  onChange,
  disabled,
}) => (
  <label className="custom-checkbox">
    <Checkbox
      disabled={disabled}
      onChange={onChange}
      aria-label={label}
      checked={checked}
      color="primary"
    />
    <span className="labelText">{label}</span>
  </label>
);

export default CustomCheckbox;
