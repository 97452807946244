import * as React from 'react';
import {
  Datagrid,
  DateField,
  List,
  ListProps,
  TextField,
  useGetIdentity,
  useTranslate,
} from 'react-admin';
import TestTypeField from '../../../components/Fields/TestTypeField';
import PartnerField from '../../../components/Fields/PartnerField';

import './styles.scss';

const TestsList = (props: ListProps) => {
  const translate = useTranslate();
  const { resource } = props;
  const { identity } = useGetIdentity();
  const isAdmin = identity?.userRoles?.includes('admin');

  const isReviewedTestsScreen = resource === 'tests/reviewed-tests';
  return (
    <List
      {...props}
      bulkActionButtons={false}
      actions={false}
      empty={<div className="error">{translate('testResults.noTests')}</div>}
      pagination={false}
    >
      <Datagrid rowClick="show">
        <TestTypeField source="type" label="tests.type" />
        {isAdmin && (
          <TextField
            sortable={false}
            emptyText="-"
            source="code"
            label="tests.code"
          />
        )}
        {isAdmin && (
          <PartnerField source="partnerCode" label="tests.columns.partners" />
        )}
        {!isAdmin && (
          <TextField
            sortable={false}
            emptyText="-"
            source="user.formattedDateOfBirth"
            label="tests.dateOfBirth"
          />
        )}
        {!isAdmin && (
          <TextField
            sortable={false}
            emptyText="-"
            source="user.initials"
            label="tests.initials"
          />
        )}
        {isReviewedTestsScreen && (
          <DateField
            showTime
            sortable={false}
            emptyText="-"
            source="reviewCompletedAt"
            label="tests.reviewCompletedAt"
          />
        )}
      </Datagrid>
    </List>
  );
};

export default TestsList;
