import React, { useState } from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  CreateProps,
  SelectInput,
  required,
  email,
  useGetIdentity,
  Loading,
  useNotify,
  useRefresh,
  useRedirect,
  useTranslate,
} from 'react-admin';

import Sidebar from './Sidebar';
import FormSpy from './FormSpy';
import ActionButton from './ActionButton';
import {
  availableTests,
  IAvailableTest,
} from '../../../constants/availableTests';

import IFormValues from './types';

import './styles.scss';
import { TEST_ASSIGNMENT_MODULE } from '../../../constants/apiUrls';

const TextAssignmentCreate = (props: CreateProps) => {
  const translate = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const { identity, loading: identityLoading } = useGetIdentity();
  const [formValues, setFormValues] = useState<IFormValues | undefined>();

  const getAvailableTests = () => {
    const isAdmin = identity?.userRoles?.includes('admin');
    if (isAdmin) {
      return availableTests;
    }

    const availableTestsForPartner = availableTests.filter(
      (test: IAvailableTest) =>
        test.availableForPartner.includes(identity?.partnerCode),
    );

    return availableTestsForPartner.map((test: IAvailableTest) => ({
      id: test.id,
      name: test.name,
    }));
  };

  const onSuccess = () => {
    notify('ra.notification.assignment_sent', 'success');
    redirect(`/${TEST_ASSIGNMENT_MODULE}`);
    refresh();
  };

  return identityLoading ? (
    <Loading />
  ) : (
    <Create
      {...props}
      onSuccess={onSuccess}
      aside={formValues && <Sidebar formValues={formValues} />}
    >
      <SimpleForm toolbar={<ActionButton />}>
        <FormSpy setFormValues={setFormValues} />
        <TextInput
          className="hidden-input"
          disabled
          source="partnerCode"
          defaultValue={identity?.partnerCode}
        />
        <SelectInput
          source="testType"
          choices={getAvailableTests()}
          validate={required()}
          label="tests.testType"
        />
        <TextInput
          source="email"
          label="tests.email"
          validate={[required(), email()]}
        />
        <SelectInput
          validate={required()}
          source="language"
          label="tests.language"
          choices={[
            { id: 'en', name: translate('languages.english') },
            { id: 'de', name: translate('languages.german') },
            { id: 'fr', name: translate('languages.french') },
            { id: 'it', name: translate('languages.italian') },
          ]}
        />
      </SimpleForm>
    </Create>
  );
};

export default TextAssignmentCreate;
