const crp = {
  parameters: [
    {
      name: 'CRP',
      displayName: 'CRP',
      id: 'crp',
      validFrom: '2022/03/07 1:00',
      validThrough: null,
      interpretationsSupported: [
        'normal',
        'too-high',
        'fault',
      ],
      ranges: [{
        color: '#217359',
        description: 'CRP-normal',
        lowerLimit: 0,
        lowerLimitOperator: '>=',
        upperLimit: 5,
        upperLimitOperator: '<',
        unit: 'mg/l',
        interpretation: 'normal',
        displayInterpretation: 'Normal',
      },
      {
        color: '#EF4036',
        description: 'CRP-too-high',
        lowerLimit: 5,
        lowerLimitOperator: '>=',
        upperLimit: 200,
        upperLimitOperator: '',
        unit: 'mg/l',
        interpretation: 'too-high',
        displayInterpretation: 'Too high',
      }],
    },
  ],
};

export default crp;