import { AxiosRequestConfig, Method } from 'axios';
import {
  RequestQueryBuilder,
  CondOperator,
  QuerySort,
  QuerySortArr,
} from '@nestjsx/crud-request';
import {
  fetchUtils,
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  DELETE,
} from 'react-admin';
import { BASE_ADMIN_URL } from '../../constants/apiUrls';
import { makeRequest } from '../requests';

const apiUrl = BASE_ADMIN_URL;
const httpClient = (
  url: any,
  options: fetchUtils.Options,
): Promise<{
  status?: number;
  headers: Headers;
  body: string;
  json: any;
}> =>
  makeRequest(<Method>options?.method || <Method>'GET', url, {
    method: <Method>options?.method || <Method>'GET',
    ...(options as any),
  }).then((response: { headers: any; data: any }) => ({
    ...response,
    headers: response.headers as any,
    body: JSON.stringify(response.data),
    json: response.data,
  }));
interface ReactAdminAxiosParams extends AxiosRequestConfig {
  data: any;
  ids: any;
  id: string;
}

const composeFilter = (paramsFilter: { q: string } | string) => {
  const paramFilter =
    paramsFilter === '' ||
    ((<{ q: string }>paramsFilter).q &&
      // @ts-ignore
      typeof paramsFilter.q !== 'undefined' &&
      // @ts-ignore
      paramsFilter.q === '')
      ? {}
      : paramsFilter;
  const flatFilter = fetchUtils.flattenObject(paramFilter);
  const filter = Object.keys(flatFilter).map((key) => {
    const splitKey = key.split('||');
    const ops = splitKey[1] ? splitKey[1] : 'cont';
    let field = splitKey[0];

    if (field.indexOf('_') === 0 && field.indexOf('.') > -1) {
      // eslint-disable-next-line prefer-destructuring
      field = field.split(/\.(.+)/)[1];
    }
    return { field, operator: ops, value: flatFilter[key] };
  });
  return filter;
};

let draftPageSort: { field: any; order: any } = { field: '', order: '' };
const convertDataRequestToHTTP = (
  type: any,
  resource: any,
  params: {
    pagination: { page: any; perPage: any };
    filter: any;
    sort: QuerySort | QuerySortArr | (QuerySort | QuerySortArr)[];
    id: any;
    ids: any;
    target: any;
    data: any;
  },
) => {
  let url = '';
  const options: Record<string, any> = {};
  switch (type) {
    case GET_LIST: {
      const { page, perPage } = params.pagination;

      const query = RequestQueryBuilder.create({
        filter: composeFilter(params.filter) as any,
      })
        .setLimit(perPage)
        .setPage(page)
        .sortBy(params.sort)
        .setOffset((page - 1) * perPage)
        .query();

      url = `${resource}?${query}`;

      break;
    }
    case GET_ONE: {
      url = `${resource}/${params.id}`;

      break;
    }
    case GET_MANY: {
      const query = RequestQueryBuilder.create()
        .setFilter({
          field: 'id',
          operator: CondOperator.IN,
          value: `${params.ids}`,
        })
        .query();

      url = `${resource}?${query}`;

      break;
    }
    case GET_MANY_REFERENCE: {
      const { page, perPage } = params.pagination;
      const filter = composeFilter(params.filter);

      filter.push({
        field: 'id',
        operator: CondOperator.EQUALS,
        value: params.id,
      });
      const query = RequestQueryBuilder.create({
        filter,
      } as any)
        .sortBy(params.sort)
        .setLimit(perPage)
        .setOffset((page - 1) * perPage)
        .query();
      draftPageSort = { ...params.sort } as any;
      url = `${resource}?${query}`;

      break;
    }
    case UPDATE: {
      url = `${resource}/${params.id}`;
      options.method = 'PATCH';
      options.body = JSON.stringify(params.data);
      options.data = params.data;
      break;
    }
    case CREATE: {
      url = `${resource}`;
      options.method = 'POST';
      options.body = JSON.stringify(params.data);
      options.data = params.data;
      break;
    }
    case DELETE: {
      url = `${resource}/${params.id}`;
      options.method = 'DELETE';
      break;
    }
    default:
      throw new Error(`Unsupported fetch action type ${type}`);
  }
  return { url, options };
};

const convertHTTPResponse = (
  response: { status?: number; headers: any; body?: string; json: any },
  type: any,
) => {
  const { json } = response;
  const compareRsult = (
    a: { [x: string]: string },
    b: { [x: string]: any },
    pageSort: { field: any; order?: any },
  ) => a[pageSort.field].localeCompare(b[pageSort.field]);
  switch (type) {
    case GET_LIST:
      return {
        data: json.data,
        total: json.total,
      };
    case GET_MANY_REFERENCE:
      return {
        data:
          draftPageSort.field !== 'id'
            ? json.data?.sort((a: any, b: any) =>
                draftPageSort.order === 'ASC'
                  ? compareRsult(a, b, draftPageSort)
                  : -compareRsult(a, b, draftPageSort),
              )
            : json.data,
        total: json.total,
      };
    case CREATE:
      return { data: { ...json.data, id: json.id } };
    default:
      return { data: { ...json, id: json.id } };
  }
};

const setHttp = (
  type: string,
  resource: string,
  params: Record<string, unknown>,
) => {
  const { url, options } = convertDataRequestToHTTP(
    type,
    resource,
    params as any,
  );
  return httpClient(url, options).then((response) => convertHTTPResponse(response, type)
  );
};

const dataProvider = {
  getList: (resource: string, params: Record<string, unknown>) =>
    setHttp('GET_LIST', resource, params),
  getOne: (resource: string, params: ReactAdminAxiosParams) =>
    setHttp('GET_ONE', resource, params as any),
  getMany: (resource: string, params: Record<string, unknown>) =>
    setHttp('GET_MANY', resource, params),
  getManyReference: (resource: string, params: Record<string, unknown>) =>
    setHttp('GET_MANY_REFERENCE', resource, params),
  create: (resource: string, params: ReactAdminAxiosParams) =>
    setHttp('CREATE', resource, params as any),
  update: (resource: string, params: ReactAdminAxiosParams) =>
    setHttp('UPDATE', resource, params as any),
  updateMany: (resource: string, params: ReactAdminAxiosParams) =>
    Promise.all(
      params.ids.map((id: any) =>
        fetchUtils.fetchJson(`${apiUrl}/${resource}/${id}`, {
          method: 'PUT',
          body: JSON.stringify(params.data),
        }),
      ),
    ).then((responses) => ({
      data: responses.map((response) => response.json),
    })),
  delete: (resource: string, params: ReactAdminAxiosParams) =>
    setHttp('DELETE', resource, params as any),
  deleteMany: (resource: string, params: ReactAdminAxiosParams) =>
    Promise.all(
      params.ids.map((id: any) =>
        httpClient(`${resource}/${id}`, {
          method: 'DELETE',
        }),
      ),
    ).then((responses) => ({
      data: responses.map((response) => response.json),
    })),
};

export default dataProvider;
