// @ts-ignore
import { useHistory } from 'react-router-dom';

import useCustomCreate from './useCustomCreate';

const useHandlePrescription = (
  type: string,
  resource: string,
) => {
  const update = useCustomCreate(type);
  const history = useHistory();
  const handleSave = async (value: { id: string; [key: string]: any }) =>
    update(resource, value.id, value, null, {
      returnPromise: true,
    })?.then(
      (
        errObject: Record<string, string | string[]> | { data: { id: string } },
      ) => {
        if (!(typeof errObject.data === 'object')) {
          const errors = errObject || {};
          return errors;
        }
        history.go(0)
        return undefined;
      },
    );

  return handleSave;
};
export default useHandlePrescription;
