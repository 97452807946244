const tshSante24 = {
  parameters: [
    {
      name: 'sante24-TSH-all-14-18',
      displayName: 'TSH',
      id: 'tsh',
      validFrom: '2021/01/05 1:00',
      validThrough: null,
      interpretationsSupported: [
        'deficiency',
        'normal',
        'too-high',
        'fault',
      ],
      criteria: {
        sexe: [
          'female',
          'male',
        ],
        age: {
          min: 14,
          max: 18,
        },
      },
      ranges: [{
        color: '#F7941F',
        description: 'sante24-TSH-all-14-18-tshDeficiency',
        lowerLimit: 0,
        lowerLimitOperator: '>=',
        upperLimit: 0.47,
        upperLimitOperator: '<',
        unit: 'mIU/l',
        interpretation: 'deficiency',
        displayInterpretation: 'Deficiency',
      },
      {
        color: '#217359',
        description: 'sante24-TSH-all-14-18-tshNormal',
        lowerLimit: 0.47,
        lowerLimitOperator: '>=',
        upperLimit: 3.41,
        upperLimitOperator: '<=',
        unit: 'mIU/l',
        interpretation: 'normal',
        displayInterpretation: 'Normal',
      },
      {
        color: '#EF4036',
        description: 'sante24-TSH-all-14-18-tshTooHigh',
        lowerLimit: 3.41,
        lowerLimitOperator: '>',
        upperLimit: 350,
        upperLimitOperator: '',
        unit: 'mIU/l',
        interpretation: 'too-high',
        displayInterpretation: 'Too high',
      }],
    },
    {
      name: 'sante24-TSH-all-19-120',
      displayName: 'TSH',
      id: 'tsh',
      validFrom: '2021/01/05 1:00',
      validThrough: null,
      interpretationsSupported: [
        'deficiency',
        'normal',
        'surplus',
        'fault',
      ],
      criteria: {
        sexe: [
          'female',
          'male',
        ],
        age: {
          min: 19,
          max: 120,
        },
      },
      ranges: [{
        color: '#F7941F',
        description: 'sante24-TSH-all-19-120-tshDeficiency',
        lowerLimit: 0,
        lowerLimitOperator: '>=',
        upperLimit: 0.35,
        upperLimitOperator: '<',
        unit: 'mIU/l',
        interpretation: 'deficiency',
        displayInterpretation: 'Deficiency',
      },
      {
        color: '#217359',
        description: 'sante24-TSH-all-19-120-tshNormal',
        lowerLimit: 0.35,
        lowerLimitOperator: '>=',
        upperLimit: 4.94,
        upperLimitOperator: '<=',
        unit: 'mIU/l',
        interpretation: 'normal',
        displayInterpretation: 'Normal',
      },
      {
        color: '#EF4036',
        description: 'sante24-TSH-all-19-120-tshTooHigh',
        lowerLimit: 4.94,
        lowerLimitOperator: '>',
        upperLimit: 350,
        upperLimitOperator: '',
        unit: 'mIU/l',
        interpretation: 'too-high',
        displayInterpretation: 'Too high',
      }],
    },
  ],
};

export default tshSante24;