import { Typography } from '@material-ui/core';
import React, { useState } from 'react';
import {
  CreateProps,
  useTranslate,
  TextInput,
  required,
  SimpleForm,
  SelectInput,
} from 'react-admin';
import useAdminSave from '../../../hooks/useAdminSave';
import messages from '../messages';
import { roles } from '../../../constants/roles';

const UsersCreate = (props: CreateProps) => {
  const translate = useTranslate();
  const handleSave = useAdminSave('create');
  const [errors, setErrors] = useState('');
  return (
    <>
      <Typography variant="h4">{translate(messages.createUser)}</Typography>
      <SimpleForm
        save={(values: any) => {
          handleSave({ ...values, roles: [values.roles] }).then((a: any) => {
            setErrors(a?.message);
          });
        }}
        {...(props as any)}
      >
        <TextInput
          source="username"
          label={translate(messages.username)}
          validate={required()}
        />
        <TextInput
          source="password"
          label={translate(messages.password)}
          validate={required()}
        />
        <SelectInput
          source="roles"
          transform={(value: string) => [value]}
          choices={Object.keys(roles).map((role) => ({
            id: role,
            name: role,
          }))}
          label={translate(messages.roles)}
          validate={required()}
        />
        <TextInput
          source="partnerCode"
          label={translate(messages.partnerCode)}
          validate={required()}
        />
      </SimpleForm>
      {errors && <Typography variant="h5">{errors}</Typography>}
    </>
  );
};

export default UsersCreate;
