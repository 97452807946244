import React from 'react';

import {
  NumberField,
  DateField,
  TextField,
  BooleanField,
  FieldProps,
} from 'react-admin';

const getFieldByType = (
  type: 'number' | 'string' | 'boolean' | 'datetime',
  props: FieldProps,
) => {
  switch (type) {
    case 'number':
      return <NumberField key={props.source} {...props} />;
    case 'string':
      return <TextField key={props.source} {...props} />;
    case 'boolean':
      return <BooleanField key={props.source} {...props} />;
    case 'datetime':
      return <DateField key={props.source} {...props} />;
    default:
      return <TextField key={props.source} {...props} />;
  }
};
export default getFieldByType;
