import React, { ChangeEvent } from 'react';
import TextField from '@material-ui/core/TextField';

interface TextareaProps {
  value: string | undefined;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  disabled?: boolean;
}

const CustomTextarea: React.FC<TextareaProps> = ({
  value,
  onChange,
  placeholder,
  disabled,
}) => {
  const textareaStyle: React.CSSProperties = {
    width: '100%',
    resize: 'none',
  };

  return (
    <TextField
      style={textareaStyle}
      placeholder={placeholder}
      multiline
      value={value}
      onChange={onChange}
      variant="outlined"
      minRows={4}
      disabled={disabled}
    />
  );
};
export default CustomTextarea;
