import React from 'react';
import { CreateButton, TopToolbar } from 'react-admin';
import './styles.scss';

const EmptyList = ({
  message,
  url,
  label,
  children,
}: {
  message: string;
  url: string;
  label: string;
  children?: React.ReactNode;
}) => (
  <>
    <TopToolbar>
      {children || <CreateButton basePath={`/${url}`} label={label} />}
    </TopToolbar>
    <div className="property-admin-empty-list">{message}</div>
  </>
);

export default EmptyList;
