export default {
  clientsListMessage: 'files.fileList',
  barcodeModule: 'barcode.name',
  prescriptionModule: 'prescription.name',
  usersModule: 'users.name',
  videoReviewModule: 'videoReview.name',
  testsModule: 'tests.newTestsName',
  immunotherapyModule: 'immunotherapy.name',
  reviewedImmunotherapyModule: 'reviewedImmunotherapy.name',
  reviewedTestsModule: 'tests.reviewedTestsName',
  testAssignmentModule: 'testAssignment.name',
};
