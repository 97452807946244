// @ts-ignore
import { useHistory } from 'react-router-dom';
import { MASS_TESTS_MODULE } from "../constants/apiUrls";
import useCustomCreate from './useCustomCreate';

const useApproveDraft = (type: string, resource: string) => {
  const update = useCustomCreate(type);
  const history = useHistory();
  const handleSave = async (value: { id: string; }) => update(resource, value.id, {}, null, {
    returnPromise: true,
  })?.then((errObject: Record<string, string> | { data: { id: string } }) => {
    if (!(typeof errObject.data === "object")) {
      const errors = errObject || {};
      return errors;
    }
    history.push('');
    history.push(`${MASS_TESTS_MODULE}`);
    return undefined;
  })
  return handleSave;
};
export default useApproveDraft;

